import React, { useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFetchPlans } from "../../hooks/useFetchPlans";
import styles from "./styles.module.scss";
import { Button } from "../../../../../../../common";
import { Lock2 } from "../../../../../../../assets/Icons";
import StripeCardElements from "../stripeCardElements";
import { useNavigate } from "react-router";
import { successToast } from "../../../../../../../utils/toaster";
import { CardLogos } from "../cardLogos/index";

export const Card = ({ isInModal }) => {
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);

  const { initiatePayment } = useFetchPlans();

  const handleChange = (event) => {
    setDisabled(event.empty || !event.complete);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setError(null);

    try {
      const { stripeClientSecret } = await initiatePayment({
        paymentMethod: "CREDIT_CARD",
        paymentProvider: "STRIPE",
      });
      const payload = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: { card: elements.getElement("cardNumber") },
      });

      console.log(payload);

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setSucceeded(false);
        return;
      } else {
        setError(null);
        setSucceeded(true);
      }
      navigate("/activate");
      successToast({ message: "Payment successful! Thank you!" });
    } catch (error) {
      console.log(error);
      setError("An unexpected error occurred.");
      setSucceeded(false);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={styles.cardWrapper}>
      {!isInModal && (
        <>
          <div className={styles.guarantee}>
            <p>30-day money-back guarantee</p>
          </div>
          <CardLogos />
        </>
      )}
      <form id="payment-form" onSubmit={handleSubmit} className={styles.form}>
        <StripeCardElements onChange={handleChange} />
        <div className={styles.control}>
          <Button disabled={processing || disabled || succeeded} id="submit">
            {processing ? (
              <div className={styles.spinner}></div>
            ) : (
              <>
                <Lock2 />
                <p>CONTINUE</p>
              </>
            )}
          </Button>
          {error && (
            <div className={styles.error} role="alert">
              {error}
            </div>
          )}
          <p
            className={
              succeeded ? `${styles.resultMessage}` : `${styles.hidden}`
            }
          >
            Payment succeeded
          </p>
        </div>
      </form>
    </div>
  );
};
