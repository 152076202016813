import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "./redux/store";
import ModalManager from "../src/common/modalManager";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense>
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EasyFit</title>
      </Helmet>
      <Toaster containerClassName="toaster-wrapper" />
      <App />
      <ModalManager />
    </Provider>
  </Suspense>
);
