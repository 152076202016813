// Libs
import { useCallback, useState, useEffect } from "react";
import { getTrainings, updateTraining } from "../../redux/slices/exerciseSlice";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { v4 as uuid } from "uuid";
import nextIcon from "../../assets/nextIcon.svg";
import classes from "./styles.module.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./videoCarousel.css";
import { Loader } from "../../common/loader";

const DayExercises = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeCarouselSlideIndex, setActiveCarouselSlideIndex] = useState(0);

  const handleSelect = useCallback((selectedIndex) => {
    setActiveCarouselSlideIndex(selectedIndex);
  }, []);

  const { allExercieses, isLoading, trainingInfo } = useSelector(
    (state) => state.exerciseSlice
  );

  const currentDay = allExercieses?.[+id - 1];

  const handleNextSlide = () => {
    setActiveCarouselSlideIndex(activeCarouselSlideIndex + 1);
  };

  const exercises = currentDay?.exercises;
  const nextExercise = exercises?.[activeCarouselSlideIndex + 1];
  const isLastSlide = activeCarouselSlideIndex === exercises?.length - 1;

  const handleFinishWorkout = async () => {
    await dispatch(updateTraining({ id: trainingInfo?.id, progress: id }));
    navigate("/profile");
  };

  useEffect(() => {
    dispatch(getTrainings());
  }, []);

  return (
    <div className={classes.page}>
      <div className={classes.backToProfileButton}>
        <p
          onClick={() => {
            navigate("/profile");
          }}
          className={classes.backToProfileText}
        >
          END
        </p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.carouselWrapper}>
            <div className={classes.carouselIndicatorsWrapper}>
              {exercises?.map((_, index) => (
                <div
                  key={uuid()}
                  className={
                    activeCarouselSlideIndex === index
                      ? `${classes.carouselIndicator} ${classes.carouselIndicatorActive} `
                      : classes.carouselIndicator
                  }
                  onClick={() => handleSelect(index)}
                />
              ))}
            </div>
            <ReactPlayer
              url={exercises?.[activeCarouselSlideIndex]?.video}
              width={"100%"}
              volume={1}
              loop={false}
              controls
              playing
              playsinline
            />
          </div>
          {!isLastSlide ? (
            <>
              <p className={classes.nextTitle}>Next</p>
              <div
                className={classes.nextVideoWrapper}
                onClick={() => handleNextSlide()}
              >
                <div className={classes.nextVideoContentWrapper}>
                  <img
                    className={classes.nextVideoImage}
                    src={nextExercise?.previewPicture}
                    alt="next"
                  />
                  <div className={classes.nextVideoInfo}>
                    <p className={classes.nextVideoTitle}>
                      {nextExercise?.name}
                    </p>
                    <p className={classes.nextVideoSubtitle}>
                      {nextExercise?.subTitle}
                    </p>
                  </div>
                </div>
                <div>
                  <img src={nextIcon} alt="next" />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className={classes.nextTitle}>Last</p>
              <div
                className={classes.nextVideoWrapper}
                onClick={handleFinishWorkout}
              >
                <div className={classes.nextVideoContentWrapper}>
                  <div className={classes.nextVideoInfo}>
                    <p className={classes.nextVideoTitle}>End the day</p>
                  </div>
                </div>
                <div>
                  <img src={nextIcon} alt="next" />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DayExercises;
