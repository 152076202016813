import styles from "./styles.module.scss";

export const ThankyouPage = () => {
  return (
    <div className={styles.thankyou}>
      <h5>Thank you for your order!</h5>
      <p>
        Following instructions are provider in email that we have been sent to
        you
      </p>
    </div>
  );
};
