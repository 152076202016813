import styles from "./styles.module.scss";
import sleep from "./img/sleep.jpg";

const DATA = {
  LESS_THAN_FIVE_HOURS: "Less than 5 hours",
  FIVE_SIX_HOURS: "5-6 hours",
  SEVEN_EIGHT_HOURS: "7-8 hours",
  MORE_THAN_EIGHT_HOURS: "More than 8 hours",
};

export const Sleep = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
  };

  return (
    <div className={styles.container}>
      <h2>How much sleep do you get every night?</h2>
      <img src={sleep} alt="sleep" className={styles.image} />
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key)}
            className={styles.card}
            key={key}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
