import { PlanCheckbox } from "../svg";
import styles from "./styles.module.scss";

const CurrencyMap = {
  USD: "$",
};

export const PlanItem = ({
  title,
  priceOld,
  priceNew,
  perDay,
  perDayOld,
  popular,
  checked,
  onClick,
  currency,
  isDiscounted,
}) => {
  return (
    <div
      className={
        checked ? `${styles.plan} ${styles.checked}` : `${styles.plan}`
      }
      onClick={onClick}
    >
      <div className={styles.container}>
        {popular && <div className={styles.mostPopular}>{popular}</div>}
        <div className={styles.planContent}>
          <div className={styles.price}>
            <PlanCheckbox checked={checked} />
            <div className={styles.priceText}>
              <h3>{title}</h3>
              <p>
                {isDiscounted ? (
                  <>
                    <span className={styles.priceOld}>
                      {CurrencyMap[currency]}
                      {priceOld}
                    </span>{" "}
                    <span>→</span> {CurrencyMap[currency]}
                    {priceNew}
                  </>
                ) : (
                  <>
                    {CurrencyMap[currency]}
                    {priceOld}
                  </>
                )}
              </p>
            </div>
          </div>
          <div className={styles.pricePerDay}>
            {isDiscounted && (
              <p className={styles.perDayOld}>
                {CurrencyMap[currency]}
                <span className={styles.strikethrough}>{perDayOld}</span>
              </p>
            )}
            <p>
              {CurrencyMap[currency]}
              {isDiscounted ? perDay : perDayOld}
            </p>

            <p className={styles.perDay}>per day</p>
          </div>
        </div>
      </div>
    </div>
  );
};
