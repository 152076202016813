import { useState } from "react";
import classes from "./styles.module.scss";
import { Link, useLocation } from "react-router-dom";
import BottomSheetContainer from "../BottomSheet";
import ProfileSettings from "../ProfileSettings";

export const ProfileLayout = ({ children, title }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openSettingBottomSheet, setOpenSettingBottomSheet] = useState(false);

  const toggleBurger = () => {
    setOpen(!open);
    document.body.classList.toggle("scroll-lock");
  };

  const handleOpenProfileSettings = () => {
    toggleBurger();
    setOpenSettingBottomSheet(true);
  };

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <p>{title}</p>
        <div
          className={
            open ? `${classes.burger} ${classes.open}` : `${classes.burger}`
          }
          onClick={toggleBurger}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <div
        className={open ? `${classes.menu} ${classes.open}` : `${classes.menu}`}
      >
        <h5 className={classes.menuTitle}>Menu</h5>
        <nav>
          <ul>
            <li>
              <Link
                className={location.pathname === "/profile" && classes.active}
                onClick={toggleBurger}
                to="/profile"
              >
                My Program
              </Link>
            </li>
            <li>
              <Link
                className={location.pathname === "/workouts" && classes.active}
                onClick={toggleBurger}
                to="/workouts"
              >
                Workouts
              </Link>
            </li>
            <li>
              <Link onClick={handleOpenProfileSettings}>Profile</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className={classes.children}>{children}</div>
      <BottomSheetContainer
        open={openSettingBottomSheet}
        setOpen={setOpenSettingBottomSheet}
      >
        <ProfileSettings />
      </BottomSheetContainer>
    </div>
  );
};
