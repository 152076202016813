import styles from "./styles.module.scss";

export const ChartGain = () => {
  return (
    <svg
      width="343"
      height="262"
      viewBox="0 0 343 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8_85)">
        <rect width="343" height="262" fill="#F8F8F8" />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 94.5)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 137)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 179.5)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 222)"
          fill="#D9D9D9"
        />
        <path
          opacity="0.2"
          d="M56 222V152.5M140.5 222V117M223 222V97.5M322.5 222V63"
          stroke="#171717"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="2 2"
        />
        <path
          className={styles.show}
          d="M124.189 115.9C56.6789 110.307 46.0474 183.49 20 204V223H323V63.2949C253.363 58.0998 228.911 123.359 124.189 115.9Z"
          fill="url(#paint0_linear_8_85)"
          fill-opacity="0.12"
        />
        <path
          d="M20 204C46.0474 183.49 56.6789 110.307 124.189 115.9C228.911 123.359 253.363 58.0998 323 63.2949"
          stroke="url(#paint1_linear_8_85)"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
          className={styles.grow}
        />
        <path
          className={styles.show}
          d="M321 91L314.23 83.7782C313.834 83.3568 313.166 83.3568 312.77 83.7782L306 91H321Z"
          fill="#7C8AF7"
        />
        <rect
          className={styles.show}
          x="258"
          y="91"
          width="72"
          height="44"
          rx="7"
          fill="#7C8AF7"
        />
        <g filter="url(#filter0_d_8_85)">
          <rect x="48" y="188" width="49" height="27" rx="7" fill="white" />
          <path
            d="M48 208L40.1225 202.311C39.5698 201.912 39.5698 201.088 40.1225 200.689L48 195L48 208Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_d_8_85)">
          <circle
            className={styles.show}
            cx="322"
            cy="63"
            r="5"
            fill="white"
            shape-rendering="crispEdges"
          />
          <circle
            className={styles.show}
            cx="322"
            cy="63"
            r="6"
            stroke="black"
            stroke-opacity="0.16"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_8_85)">
          <circle
            cx="21"
            cy="203"
            r="5"
            fill="white"
            shape-rendering="crispEdges"
          />
          <circle
            cx="21"
            cy="203"
            r="6"
            stroke="black"
            stroke-opacity="0.16"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8_85"
          x="23"
          y="172"
          width="90"
          height="59"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_85"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_85"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8_85"
          x="307"
          y="48"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_85"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_85"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8_85"
          x="6"
          y="188"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_85"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_85"
            result="shape"
          />
        </filter>
        <linearGradient
          className={styles.show}
          id="paint0_linear_8_85"
          x1="20"
          y1="223"
          x2="309.263"
          y2="74.0415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F91E1E" />
          <stop offset="0.26" stop-color="#FFA51F" />
          <stop offset="0.465" stop-color="#F9ED2A" />
          <stop offset="0.725" stop-color="#64EC82" />
          <stop offset="1" stop-color="#36E6E6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_85"
          x1="20"
          y1="133.614"
          x2="323"
          y2="133.614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F91E1E" />
          <stop offset="0.26" stop-color="#FFA51F" />
          <stop offset="0.465" stop-color="#F9ED2A" />
          <stop offset="0.725" stop-color="#64EC82" />
          <stop offset="1" stop-color="#36E6E6" />
        </linearGradient>
        <clipPath id="clip0_8_85">
          <rect width="343" height="262" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
