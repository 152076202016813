import { useEffect, useState } from "react";

const useShowApplePayButton = () => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  useEffect(() => {
    // Check if Apple Pay is available on component mount
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setIsApplePayAvailable(true);
    }
  }, []);

  return isApplePayAvailable;
};

export default useShowApplePayButton;
