export const keyMapping = {
  "profile-3": "qqGoal",
  "profile-6": "qqWeightFluctuation",
  "lifestyle-2": "qqDayRoutine",
  "lifestyle-3": "qqEnergyLevels",
  "lifestyle-4": "qqDailyWaterIntake",
  "lifestyle-5": "qqSleep",
  "lifestyle-1": "qqWorkLife",
  "activity-1": "qqFitnessLevel",
  "activity-4": "qqFlexibility",
  "activity-5": "qqStairs",
  "activity-8": "qqExerciseFrequency",
  "activity-9": "qqWalksFrequency",
  "nutrition-1": "qqBreakfastTime",
  "nutrition-2": "qqLunchTime",
  "nutrition-3": "qqDinnerTime",
  "almost_done-2": "qqHeightCm",
  "almost_done-3": "qqWeightKg",
  "almost_done-4": "qqGoalWeightKg",
  "almost_done-5": "qqAge",
  "almost_done-7": "qqOccasion",
  "almost_done-8": "qqOccasionDate",
};
