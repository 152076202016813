import { useState } from "react";
import { Button, Input } from "../../../../../common";
import { useMeasureSystem } from "../../../../../hooks/useMeasureSystem";
import { cmToFt, ftToFtIn } from "../../../../../utils/measurements";
import styles from "./styles.module.scss";
import { Checkbox, CheckboxChecked } from "../../../../../assets/Checkbox";
import { Link } from "react-router-dom";
import { UserService } from "../../../../../api/services/userService";

export const Height = ({ onNextStep }) => {
  const { ms, changeMs } = useMeasureSystem();
  const [heightEu, setHeightEu] = useState();
  const [heightUS, setHeightUS] = useState({ ft: undefined, inch: undefined });
  const [isValid, setIsValid] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChangeCm = (e) => {
    const cm = e.currentTarget.value;

    if (cm === "") {
      setHeightEu(undefined);
      setIsValid(false);
      return;
    }

    if (cm.length <= 3 && !isNaN(cm)) {
      const numericValue = Number(cm);
      setHeightEu(numericValue);
      setIsValid(numericValue <= 241 && numericValue >= 90);
    }
  };

  const handleChangeFt = (e) => {
    const ft = e.currentTarget.value;

    if (ft === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        ft: undefined,
      }));
      setIsValid(false);
      return;
    }

    if (ft.length <= 1 && !isNaN(ft)) {
      const numericValue = Number(ft);

      setHeightUS((prevState) => ({
        ...prevState,
        ft: Number(ft),
      }));
      setIsValid(numericValue <= 7 && numericValue >= 3);
    }
  };

  const handleChangeInch = (e) => {
    const inch = e.currentTarget.value;

    if (inch === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        inch: undefined,
      }));
      setIsValid(false);
      return;
    }

    if (inch.length <= 2 && !isNaN(inch)) {
      const numericValue = Number(inch);

      setIsValid(numericValue <= 12);
      setHeightUS((prevState) => ({
        ...prevState,
        inch: Number(inch),
      }));
    }
  };

  const handleNextStep = async () => {
    const payload =
      ms === "us"
        ? { qqHeightFt: heightUS.ft, qqHeightIn: heightUS.inch || 0 }
        : { qqHeightCm: heightEu };
    try {
      await UserService.updateQuestions(payload);
      onNextStep();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How tall are you?</h2>
        <div className={styles.switch}>
          <div
            onClick={() => changeMs("us")}
            className={
              ms === "us" ? `${styles.item} ${styles.active}` : `${styles.item}`
            }
          >
            ft
          </div>
          <div
            onClick={() => changeMs("eu")}
            className={
              ms === "eu" ? `${styles.item} ${styles.active}` : `${styles.item}`
            }
          >
            cm
          </div>
        </div>
        <div className={styles.input}>
          {ms === "eu" ? (
            <Input
              inputmode="numeric"
              type="number"
              step="1"
              placeholder={"Height"}
              value={heightEu}
              onChange={handleChangeCm}
              endAdornment={<h5>cm</h5>}
            />
          ) : (
            <div className={styles.usInputs}>
              <Input
                inputmode="numeric"
                onChange={handleChangeFt}
                type="number"
                step="1"
                value={heightUS.ft}
                placeholder={"Height"}
                endAdornment={<h5>ft</h5>}
              />
              <Input
                inputmode="numeric"
                value={heightUS.inch}
                maxLength={3}
                onChange={handleChangeInch}
                type="number"
                step="1"
                placeholder={"Height"}
                endAdornment={<h5>in</h5>}
              />
            </div>
          )}
          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>
              {ms === "eu"
                ? `${92} cm to ${243} cm`
                : `${cmToFt(90).toFixed(0)} ft to ${ftToFtIn(
                    cmToFt(241)
                  ).ft.toFixed(0)} ft ${ftToFtIn(cmToFt(243)).inch.toFixed(
                    0
                  )} in`}
            </span>
          </p>
        </div>
      </div>
      <div className={styles.action}>
        <div className={styles.policy}>
          <div onClick={() => setChecked(!checked)}>
            {checked ? <CheckboxChecked /> : <Checkbox />}
          </div>
          <div className={styles.policyText}>
            I consent to EasyFit processing my health onboarding data to provide
            services and enhance my user experience.{" "}
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
        <Button onClick={handleNextStep} disabled={!isValid || !checked}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
