import toast from "react-hot-toast";

export const errorToast = ({ message }) => {
  toast(() => message, {
    duration: 4000,
    id: "error",
    style: {
      padding: "13px",
      textAlign: "center",
      width: "100%",
      margin: 0,
      backgroundColor: "#f5e8e8",
      color: "#FF4F64",
      maxWidth: "450px",
      marginTop: "12px",
      fontSize: "16px",
      border: "1px solid #FF4F6433",
    },
  });
};

export const successToast = ({ message }) => {
  toast(() => message, {
    duration: 4000,
    style: {
      padding: "13px",
      fontSize: "16px",
      margin: 0,
      textAlign: "center",
      width: "100%",
      maxWidth: "450px",
      marginTop: "12px",
      backgroundColor: "#e8f5f2",
      color: "#058d66",
    },
  });
};
