import { useEffect, useState } from "react";
import { useDiscount } from "../routes/onboarding/paywall/DiscountContext";

const useDiscountCountdown = () => {
  const { setIsDiscounted } = useDiscount();

  const interval = 60 * 10 * 1000;
  const [remainingTime, setRemainingTime] = useState(() => {
    const savedEndTime = localStorage.getItem("endTime");
    return savedEndTime ? Math.max(savedEndTime - Date.now(), 0) : interval;
  });

  useEffect(() => {
    if (!localStorage.getItem("endTime")) {
      localStorage.setItem("endTime", Date.now() + interval);
    }

    const updateRemainingTime = () => {
      const endTime = localStorage.getItem("endTime");
      const timeLeft = endTime ? Math.max(endTime - Date.now(), 0) : 0;

      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
      } else {
        setIsDiscounted(false);
        clearInterval(timerInterval);
      }
    };

    const timerInterval = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(timerInterval);
  }, [setIsDiscounted, interval]);

  const minutes = Math.floor(remainingTime / 60000);
  const seconds = Math.floor((remainingTime % 60000) / 1000);
  const displayMinutes = String(minutes).padStart(2, "0");
  const displaySeconds = String(seconds).padStart(2, "0");

  const time = { minutes, seconds, displayMinutes, displaySeconds };

  return { time };
};

export default useDiscountCountdown;
