import { combineReducers, configureStore } from "@reduxjs/toolkit";
import exerciseSlice from "./slices/exerciseSlice";
import { reducer as subscription } from "./slices/subscriptionSlice";
import { reducer as modal } from "./slices/modalSlice";
import { reducer as user } from "./slices/userSlice";
import { reducer as plans } from "./slices/plans";

const reducer = combineReducers({
  exerciseSlice,
  subscription,
  modal,
  user,
  plans,
});

const store = configureStore({
  reducer,
});

export default store;
