// Libs
import { useDispatch, useSelector } from "react-redux";
import { resetUserProgress, logoutUser } from "../../redux/slices/exerciseSlice";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

// Components
import SubmitButton from "../../common/SubmitButton";

import subscriptionPlanService from "../../api/services/subscriptionPlanService";

import classes from "./styles.module.scss";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoadingResetProgress = useSelector(
    (state) => state.slices.isLoadingResetProgress
  );

  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const response = await subscriptionPlanService.getSubscriptionActive();

      if (response.length > 0 && response[0].state !== "CANCELLED") {
        setSubscriptionData(response[0]);
      } else {
        setSubscriptionData(null);
      }
    };

    checkSubscriptionStatus();
  }, []);

  const handleCancelSubscription = async () => {
    if (subscriptionData) {
      const subscriptionId = subscriptionData.id;

      const cancelSubscription =
        await subscriptionPlanService.cancelSubscriptionById(subscriptionId);

      if (cancelSubscription) {
        setSubscriptionData(null);
      }
    }
  };

  return (
    <div className={classes.profileSettingsContainer}>
      <p className={classes.profileSettingsTitle}>Profile</p>
      <SubmitButton
        onClick={() => {
          if (!isLoadingResetProgress) dispatch(resetUserProgress(0));
        }}
        title="Reset progress"
        type="button"
        isLoading={isLoadingResetProgress}
        variant="greenBackground"
      />
      <button
        onClick={() => {
          localStorage.removeItem("FY-AuthToken");
          dispatch(logoutUser());
          navigate("/auth");
        }}
        className={classes.logOutButton}
      >
        Log out
      </button>
      {subscriptionData && subscriptionData.state !== "CANCELLED" && (
        <button
          className={classes.cancelSubscription}
          onClick={handleCancelSubscription}
        >
          Cancel subscription
        </button>
      )}
    </div>
  );
};

export default ProfileSettings;
