import { axiosInstance } from "..";

export const UserService = {
  updateQuestions: (data) => {
    return axiosInstance.patch("me/questions", data);
  },
  userInfo: () => {
    return axiosInstance.get("me");
  },
};
