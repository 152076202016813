import { OnboardingLayout } from "../../../../common";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "../../stepper/fade.css";
import { useRef } from "react";
import styles from "./styles.module.scss";

export const RegistrationLayout = ({ children, hideBack }) => {
  const nodeRef = useRef(null);
  return (
    <OnboardingLayout hideNavigateBack={hideBack}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={children}
          in={true}
          nodeRef={nodeRef}
          timeout={700}
          classNames="fade-in"
        >
          <div ref={nodeRef} className={styles.step}>
            {children}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </OnboardingLayout>
  );
};
