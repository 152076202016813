import React, { createContext, useContext } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const StripeElementsContext = createContext();

export const StripeElementsProvider = ({ children }) => {
  return (
    <StripeElementsContext.Provider
      value={{ CardNumberElement, CardExpiryElement, CardCvcElement }}
    >
      {children}
    </StripeElementsContext.Provider>
  );
};

export const useStripeElements = () => useContext(StripeElementsContext);
