import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Transition } from "react-transition-group";

const CATEGORIES = ["underweight", "normal", "overweight", "obese"];

const BMIScale = ({ bmi }) => {
  const nodeRef = useRef();
  let bmiType = "normal";

  if (bmi <= 18.5) {
    bmiType = "underweight";
  } else if (bmi >= 26 && bmi <= 30) {
    bmiType = "overweight";
  } else if (bmi >= 31) {
    bmiType = "obese";
  }

  const getBMILabel = (index) => {
    if (index > 40) {
      return `>40`;
    } else if (index < 15) {
      return `<15`;
    }
    return index;
  };

  function getScalePosition(value) {
    const scaleMarkers = [15, 18.5, 25, 30, 40];

    if (value < scaleMarkers[0]) {
      return 0;
    }
    if (value > scaleMarkers[scaleMarkers.length - 1]) {
      return 100;
    }

    for (let i = 0; i < scaleMarkers.length - 1; i++) {
      if (value >= scaleMarkers[i] && value <= scaleMarkers[i + 1]) {
        const intervalStart = scaleMarkers[i];
        const intervalEnd = scaleMarkers[i + 1];
        const intervalPercentage =
          (value - intervalStart) / (intervalEnd - intervalStart);

        const overallPercentage =
          ((i + intervalPercentage) / (scaleMarkers.length - 1)) * 100;
        return overallPercentage;
      }
    }

    return 100;
  }

  const getTooltipAnchorPosition = () => {
    if (getScalePosition(bmi) < 10) {
      return styles.left;
    }
    if (getScalePosition(bmi) > 90) {
      return styles.right;
    }
    return styles.center;
  };

  const defaultStyle = {
    left: 0,
    opacity: 0,
    transition: " .9s ease",
  };

  const transitionStyles = {
    entering: { left: `${getScalePosition(bmi)}%`, opacity: 1 },
    entered: { left: `${getScalePosition(bmi)}%`, opacity: 1 },
  };

  return (
    <div className={styles.bmiScale}>
      <div className={styles.rangeLabels}>
        <span>15</span>
        <span>18.5</span>
        <span>25</span>
        <span>30</span>
        <span>40</span>
      </div>
      <div className={styles.scale}>
        <Transition in={!!bmi} timeout={500} nodeRef={nodeRef}>
          {(state) => (
            <div
              ref={nodeRef}
              className={`${styles.pointer} ${getTooltipAnchorPosition()}`}
              style={{ ...defaultStyle, ...transitionStyles[state] }}
            >
              <div className={styles.tooltip}>You - {getBMILabel(bmi)}</div>
              <div className={styles.line}></div>
              <div className={styles.slider}></div>
            </div>
          )}
        </Transition>
      </div>
      <div className={styles.categories}>
        {CATEGORIES.map((category) => (
          <span
            key={category}
            className={bmi && category === bmiType ? styles.active : ""}
          >
            {category}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BMIScale;
