export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatFitnessProfile = (report) => {
  return {
    bodyMassIndex: report.bodyMassIndex,
    bodyMassType: capitalizeFirstLetter(report.bodyMassType),
    bodyType: capitalizeFirstLetter(report.bodyType),
    fitnessLevel: capitalizeFirstLetter(report.fitnessLevel),
    lifestyleType: report.lifestyleType
      ? capitalizeFirstLetter(report.lifestyleType)
      : "n/a",
    metabolismType: capitalizeFirstLetter(report.metabolismType),
  };
};
