import { TermsWrapper } from "./TermsWrapper";
import classes from "./styles.module.scss";

export const PrivacyPolicy = () => {
  return (
    <TermsWrapper
      title="Privacy Policy"
      lastUpdated={new Date("2024-09-23").toLocaleDateString()}
    >
      <div className={classes.item}>
        <p>
          This Privacy Notice is to inform you, as a user of the EasyFit app
          and/or related website (further the <strong>“App”</strong> and the{" "}
          <strong>“Website”</strong>
          respectively, together <strong>“we/us”</strong>, the{" "}
          <strong>“Product”</strong>), on the processing of your personal data
          in connection with the use of the Product.
        </p>
        <p>
          When we say “personal data” or “personal information”, we are
          referring to information related to you. Such information is not
          limited to what directly identifies you, such as your name. In fact,
          you own and have legal rights to anything that speaks of you: the data
          you enter about your food intake, fitness experience, your aim for
          using the Product, even the way you use the Product itself. To avoid
          legalese, we can also use “your data” or “your information” in the
          same meaning.
        </p>
        <p>
          {" "}
          This notice is meant to provide you with all relevant information to
          make an informed choice to use the Product. It also serves to inform
          you of your rights and how you can exercise them. Contact us at{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>. if
          you have any questions or concerns.{" "}
        </p>
        <p>
          <strong>
            Please note that the Product is intended for users who have reached
            the minimum legal age. Therefore, we expect you will not use the
            Product unless you are at least 16 or older.
          </strong>
        </p>
        <p>
          <i>
            This Notice was written in English. to the extent a translated
            version of the Notice conflicts with the English version, the
            English version prevails.
          </i>
        </p>
      </div>
      <div className={classes.item}>
        <h2>1. Who We Are</h2>
        <p>
          The controller responsible for processing with regard to the Product
          is Straiton Limited, with its registered address at Office 11, 1st
          floor, Alpha Tower Pavlou, Nirvana - Aipeias, 3021 Limassol, Cyprus.
        </p>
      </div>
      <div className={classes.item}>
        <h2>2. Contact Us</h2>

        <p>
          Should you have any general questions about this Privacy Notice, the
          use of your data by us, or if you want to assert your data protection
          rights, please use the following interchangeable contact options:
        </p>
        <p className={classes.marginLeft}>
          if relating to your use of the App or Website:{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>
        </p>

        <p>
          Please note that in some cases, for your safety and when we have
          reasonable concerns about the authenticity of a request, we may need
          to authenticate you before proceeding with your request.
        </p>
      </div>
      <div className={classes.item}>
        <h2>3. Your Privacy Rights and How to Exercise Them</h2>
        <p>
          You are entitled to data-related rights as described below. You may
          assert them directly via the App (to the extent possible) or via the
          contact options.
        </p>
        <p>
          Please note that these rights are based on European laws, namely the
          General Data Protection Regulation (the <strong>“GDPR”</strong>).
          Depending on your country of residence, those rights may have
          different interpretations. Should you wish to assert your
          country-specific rights, kindly let us know, and we will review your
          case individually.
        </p>
        <h3>Right to access</h3>
        <p>
          You may ask to confirm whether we process your data and if so, to
          provide you with the scope of such processing and a copy of the data
          concerning you stored by us.
        </p>
        <h3>Right to rectification</h3>
        <p>
          You have the right to request correction of inaccurate or outdated
          personal data concerning you and the completion of incomplete data. We
          try to allow you to correct/add data through the Product’s settings to
          the extent possible, e.g. you can log in to your user profile and
          correct, amend, or delete information about yourself. We will also
          inform third parties to whom we transferred your data in the event of
          rectification of your data.
        </p>
        <h3>Right to erasure (Right to be forgotten)</h3>
        <p>
          You may request the erasure of your personal data stored by us. Please
          note, however, that the right to erasure is not absolute and shall be
          fulfilled by us only provided specific legal requirements are met. You
          may request data erasure only if:
        </p>
        <ul>
          <li>
            your personal data is no longer necessary for the purposes for which
            it was initially collected,
          </li>
          <li>
            you have withdrawn your consent to personal data processing, and the
            consent was the sole legal basis for the processing,
          </li>
          <li>
            you have successfully objected to the processing based on a
            balancing of interests relating to your particular situation,
          </li>
          <li>your personal data has been unlawfully processed,</li>
          <li>
            your personal data has to be erased for compliance with a legal
            obligation, or
          </li>
          <li>you are an underage person and used our Product by mistake.</li>
        </ul>
        <p>
          Further, your right to erasure is subject to restrictions. For
          example, we are not under obligation or even allowed to delete data
          that we are still obligated to retain due to statutory retention
          periods. Similarly, data that we need for the establishment, exercise,
          or defense of legal claims is excluded from your right to erasure.
        </p>
        <p>
          If we have transferred personal data to third parties, we will either
          initiate the deletion of your data from such third parties or inform
          them about the erasure, insofar as required by applicable law.
        </p>
        <p>
          In order to avoid any unnecessary legalese and keeping in mind the
          general use of such words, we will interpret all user requests asking
          us to “delete my data” as requests for erasure of data under Article
          17 GDPR.
        </p>
        <h3>Right to restriction of processing</h3>
        <p>
          You have the right to request, under certain conditions, restriction
          of processing (i.e. the marking of stored personal data to restrict
          its future use). The requirements for restriction of processing are as
          follows:
        </p>
        <ul>
          <li>
            The accuracy of your personal data is contested, and we must verify
            the accuracy of your data;
          </li>
          <li>
            The processing is unlawful but you do not want your personal data to
            be erased, and instead you request restriction of use of your
            personal data;
          </li>
          <li>
            We no longer need your personal data for the purposes of processing,
            but you need the data for the establishment, exercise, or defense of
            legal claims;
          </li>
          <li>
            You have objected to data processing, and we are verifying whether
            our legitimate interests override yours.
          </li>
        </ul>
        <h3>Right to objection </h3>
        <p>
          You have the right to object to the processing of your personal data
          in whole or in part at any time, and in any form, in the event that
          our processing is based on a legitimate interest (balancing of
          interests). In order to process your objection, we kindly request you
          to refer to your particular situation as to why you believe that your
          rights and freedoms are at a particular risk (see Article 21 of GDPR
          for more details).{" "}
        </p>
        <p>
          Please note that the right to objection is not absolute, and we will
          stop the processing of your data only provided we cannot demonstrate
          compelling legitimate grounds for processing.{" "}
        </p>
        <p>
          Where the processing you object against concerns direct marketing
          purposes, we will stop any processing once we receive an objection
          from you. A common example of how you can exercise an objection to
          marketing communications is pressing the “unsubscribe” button in
          emails.
        </p>
        <h3>Right to data portability</h3>
        <p>
          You have the right to receive the data you provided to us in a
          commonly used and machine-readable format to transfer that data to
          another controller without our interference, in accordance with the
          applicable laws and regulations.{" "}
        </p>
        <p>
          To the extent possible, we will allow you to export data for further
          use directly via the App. You may also ask us to assist you with
          moving your data where it is technically feasible. Please note that
          this option relates only to data processed based on the performance of
          our contract with you or based on your consent.{" "}
        </p>
        <h3>Right to withdraw consent</h3>
        <p>
          If you provide consent to the processing of your personal data, you
          can withdraw it at any time. Consent withdrawal does not have a
          retroactive effect, meaning that any processing which occurred before
          the withdrawal shall not be affected.
        </p>
        <h3>Right to lodge a complaint</h3>
        <p>
          You can lodge a complaint with the data protection authority at any
          time if you believe that your data has been processed unlawfully.{" "}
        </p>
      </div>
      <div className={classes.item}>
        <h2>3.1. California Privacy Rights </h2>
        <p>
          In this Section, you can find a description of your privacy rights as
          a California resident, which are established under California laws,
          such as the California Consumer Privacy Act (the{" "}
          <strong>“CCPA”</strong>).
        </p>
        <p>
          Please note that in this Section 3.1, we describe California-specific
          rights that substantially differ from those established under the
          GDPR. These rights are in addition to those established under the GDPR
          described above in Section 3 for California residents.{" "}
        </p>
        <p>
          You may assert these rights directly via the Product (to the extent
          possible) or via the contact options.{" "}
        </p>
        <h3>Access to specific information and data portability rights</h3>
        <p>
          Generally, your right to request data access and data portability are
          described above in Section 3 under the titles “Right to access” and
          “Right to rectification”, respectively. The scopes of these rights
          differ slightly under the CCPA.{" "}
        </p>
        <p>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months only. Once we receive and confirm your verifiable
          consumer request, we will disclose to you:
        </p>
        <ul>
          <li>
            The categories of personal information we collected about you.
          </li>
          <li>
            The categories of sources for the personal information we collected
            about you.
          </li>
          <li>
            Our business or commercial purpose for collecting or selling that
            personal information.
          </li>
          <li>
            The categories of third parties with whom we share that personal
            information.
          </li>
          <li>
            The specific pieces of personal information we collected about you.
          </li>
        </ul>
        <p>
          If we sold or disclosed your personal information for a business
          purpose, we will disclose such facts separately.
        </p>
        <p>
          Please note that you may only make a verifiable consumer request for
          access or data portability twice within a 12-month period. The
          verifiable consumer request must:
        </p>
        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative of such person.
          </li>
          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </ul>
        <h3>Right to request deletion of data</h3>
        <p>
          Generally, your right to request erasure is described above in Section
          3 under the name “Right to erasure (Right to be forgotten)”.{" "}
        </p>
        <p>
          Despite the fact that the right to request deletion of data under the
          CCPA and the right to erasure under the GDPR are essentially the same,
          please note that under the CCPA we are under no obligation to comply
          with your request if we need to maintain your information for certain
          reasons. For your convenience, you may find below an abridged version
          of such exceptions. To learn more, please refer to the full text of
          the CCPA or the additional clarifications from the California Attorney
          General.
        </p>
        <p>
          Under the CCPA, we may retain your personal information if we need it
          to, among other things:
        </p>
        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, or fulfill the terms of a written warranty or product
            recall.
          </li>
          <li>
            Detect security incidents; protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity.
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended
            functionality.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with the
            expectations of the consumer based on the consumer’s relationship
            with the business.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>
            Otherwise use the personal information internally in a lawful manner
            that is compatible with the context in which the consumer provided
            the information, etc.
          </li>
        </ul>
        <h3>Right to non-discrimination</h3>
        <p>
          California consumers have the right to non-discrimination in relation
          to the exercise of their rights under the CCPA. In particular, we
          cannot deny you the Product, charge you different prices for the
          Product, either by denying benefits or imposing penalties, provide you
          with a different level or quality of the Product in comparison to how
          we proceed with other California users, or threaten you with any of
          the above.
        </p>
        <h3>
          Right to request information on data transfer for third party’s direct
          marketing purposes
        </h3>
        <p>
          Under California’s “Shine the Light” law (Civil Code Section §
          1798.83) users can request information regarding the disclosure of
          personal information to third parties for their direct marketing
          purposes.
        </p>
        <h3>Right to prohibit sale of information</h3>
        <p>
          We do not sell the personal data we collect (as defined in the CCPA)
          and will not sell it without providing you with the right to opt out.
          Please note that we share users’ personal information for our business
          purposes as described in Section 7 “Personal Data Transfers”.
        </p>
      </div>
      <div className={classes.item}>
        <h2>3.2. Brazil Privacy Rights</h2>
        <p>
          In this Section, you can find a description of your privacy rights as
          a data subject located in Brazil, which are established under the
          Brazilian General Data Protection Law (the <strong>“LGPD”</strong>).
        </p>
        <p>
          Please note that in this Section 3.2, we describe Brazil-specific
          rights that substantially differ from those established under the
          GDPR. These rights are in addition to those established under the GDPR
          described above in Section 3 for data subjects located in Brazil.
        </p>
        <p>
          You may assert these rights directly via the Product (to the extent
          possible) or via the contact options.
        </p>
        <h3>Right to request anonymization/blockage/erasure</h3>
        <p>
          You may request the anonymization/blockage/erasure of your personal
          data stored by us. Please note, however, that this right is not
          absolute and shall be fulfilled by us only provided specific legal
          requirements are met. You may request them only if your personal data
          is unnecessary, excessive or has been unlawfully processed.
        </p>
        <h3>Right to obtain information about shared data</h3>
        <p>
          You have the right to obtain information about the entities with which
          we have shared your personal information.
        </p>
        <h3>Right to lodge a complaint</h3>
        <p>
          You can lodge a complaint with the Brazilian National Data Protection
          Authority (ANPD) at any time if you believe that your data has been
          processed unlawfully.
        </p>
      </div>
      <div className={classes.item}>
        <h2>4. Personal Data We Collect</h2>
        <p>
          Your user journey may vary depending on whether it is the App or the
          Website (or both) you have chosen to use. Similarly, the scope and
          peculiarities of data processing will vary:
        </p>
        <ul>
          <li>
            if you access the Product through the Website, both Website Data
            Collection and App Data Collection will apply;{" "}
          </li>
          <li>
            if you access the Website only, Website Data Collection will apply;{" "}
          </li>
          <li>
            if you accessed the App via an application store, only App Data
            Collection will apply.
          </li>
        </ul>

        <table className={classes.table}>
          <caption>Data you provide</caption>
          <thead>
            <tr>
              <th>Data Category</th>
              <th>Website Data Collection</th>
              <th>App Data Collection</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>General Information</strong>
              </td>
              <td>
                <p>
                  When you start using the Website, or in the course of Website
                  use, we collect the following personal data:
                </p>
                <ul>
                  <li>Name,</li>
                  <li>Email address,</li>
                  <li>Birth year or age (inferred based on the birth year),</li>
                  <li>Country of residence,</li>
                  <li>Sex.</li>
                </ul>
              </td>
              <td>
                <p>
                  When you start using the App, or in the course of App use, we
                  collect the following personal data:
                </p>
                <ul>
                  <li>Name,</li>
                  <li>Email address,</li>
                  <li>Birth year, age (inferred based on the birth year),</li>
                  <li>Country of residence,</li>
                  <li>Sex.</li>
                </ul>
                <p>
                  If you accessed the App from the Website, we may use the
                  information already collected on the Website for the App.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Fitness & Wellness Onboarding Information</strong>
              </td>
              <td colspan="2">
                <p>
                  To tailor your Product experience to your needs, we may ask
                  you to answer some questions about your fitness and wellness
                  experience.
                </p>
                <p>
                  Such Fitness & Wellness Onboarding Information may include
                  your height and weight, target weight and body type and target
                  zones, fitness level, sleep information, well-being details,
                  etc.
                </p>
                <p>
                  If you do not provide us with Fitness & Wellness Onboarding
                  Information, we will not be able to create a personalized
                  fitness experience for you.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Payment Data</strong>
              </td>
              <td>
                <p>
                  To make a purchase via the Website, you need to enter your
                  payment information, such as bank card credentials, payment
                  service (e.g. PayPal) credentials, etc.
                </p>
                <p>
                  We may or may not have access to such payment information
                  depending on the service provider processing your payment.
                </p>
                <p>
                  Payment gateways, such as PayPal, Stripe, and Braintree, act
                  independently; we receive limited information from them
                  allowing us to recognize and record your subscription status,
                  such as the date, time, and amount of the transaction, the
                  type of payment method used, the payment transaction
                  identification number, and the last four digits of the bank
                  card.
                </p>
                <p>
                  Our one-stop shop payment provider acts on our behalf; it
                  collects and otherwise processes your payment data, including
                  bank card credentials, IP address, email, and name, on our
                  behalf. In practice, it shares with us only limited details
                  regarding your payment, such as the date, time, and amount of
                  the transaction, so our employees/contractors cannot view your
                  payment credentials.
                </p>
              </td>
              <td>
                <p>N/A</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Customer Support and Other Communications Information{" "}
                </strong>
              </td>
              <td colspan="2">
                <p>
                  When you contact us for customer support or otherwise
                  communicate with us, you may provide us with information
                  related to your use of the Product, additional contact
                  information, payment information, identification documents, or
                  other information you may find helpful to resolve your query.
                  We also collect information on the feedback you provide on the
                  customer support experience.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Your User Choices</strong>
              </td>
              <td>
                <p>We collect:</p>
                <ul>
                  <li>
                    information about your Website settings, privacy settings,
                    and{" "}
                  </li>
                  <li>
                    records about when you accepted our Terms of Use, Privacy
                    Notice, Refund Policy, and/or provided consent to marketing
                    communications;
                  </li>
                  <li>
                    information on whether you have enabled web browser
                    notifications.
                  </li>
                </ul>
              </td>
              <td>
                <p>We collect:</p>
                <ul>
                  <li>
                    information about your in-App settings, privacy settings,
                    and{" "}
                  </li>
                  <li>
                    records about when you accepted our Terms of Use, Privacy
                    Notice, Subscriptions Policy, and/or provided consent to the
                    processing of your data or to marketing communications;
                  </li>
                  <li>
                    information about whether you have enabled push
                    notifications.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Feedback Information</strong>
              </td>
              <td>
                <p>
                  We will collect and process your feedback should you
                  communicate it to us.
                </p>
              </td>
              <td>
                <p>
                  Our App contains a feature via which you can provide us with
                  feedback, rate our application, and/or engage in deeper
                  discussions. We will collect and analyze the data you submit
                  to make our Product better.{" "}
                </p>
                <p>
                  We will also collect and process feedback should you
                  communicate it to us in any other way.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={classes.table}>
          <caption>Automatically collected data</caption>
          <thead>
            <tr>
              <th>Data Category</th>
              <th>Website Data Collection</th>
              <th>App Data Collection</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Usage Information</strong>
              </td>
              <td>
                <p>
                  We collect information about your activity on our Website,
                  like frequency of use, areas and features that you access,
                  visit, or use, and engagement with particular features.
                </p>
              </td>
              <td>
                <p>
                  We collect information about your activity on our App, such
                  as:
                </p>
                <ul>
                  <li>
                    Product interaction, e.g. frequency of use, areas and
                    features that you access, visit, or use, and engagement with
                    particular features.
                  </li>
                  <li>
                    In-App fitness activities, like performed/active workouts,
                    workout choices, etc.
                  </li>
                  <li>
                    Access to external groups/communities created by the App for
                    additional communications, feedback collection.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Device-Related Information </strong>
              </td>
              <td>
                <p>
                  When you install, access, or use the Website, we collect
                  device-related information. This includes IP address, user
                  agent, hardware model, operating system information, browser
                  information, language and time zone, etc.{" "}
                </p>
                <p>
                  Device-related information also includes approximate location
                  information.
                </p>
              </td>
              <td>
                <p>
                  When you install, access, or use the App, we collect
                  device-related information. This includes IP address, user
                  agent, hardware model, operating system information, App
                  version, browser information, mobile network, language and
                  time zone, device identifiers, including Android Advertising
                  Identifier (GAID)/Apple ID for Advertisers (IDFA), etc.{" "}
                </p>
                <p>
                  Device-related information also includes approximate location
                  information.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Subscription Status</strong>
              </td>
              <td>
                <p>N/A</p>
              </td>
              <td>
                <p>
                  By exchanging information with your application store, we
                  understand and record your subscription status.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Log and Troubleshooting Information </strong>
              </td>
              <td colspan="2">
                <p>
                  We collect some troubleshooting, bug, and crash reporting
                  information from you to help determine problems with the
                  Product. We also collect and write logs to be able to access
                  historical data for internal and external investigations.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={classes.table}>
          <caption>Information third parties provide about you</caption>
          <thead>
            <tr>
              <th>Data Category</th>
              <th>Website Data Collection</th>
              <th>App Data Collection</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Advertising Network Information </strong>
              </td>
              <td colspan="2">
                Advertising networks provide us with insights on which channels
                (e.g. social networks) and which particular ad campaigns helped
                you reach the Product.
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Cookies and Similar Technologies</h3>
        <p>
          On the Website, we also use cookies and other tracking technologies
          for various purposes. Please refer to Section 6 for more information
          on cookies and similar technologies.
        </p>
        <h3>On-device Data Collection</h3>
        <p>
          There may be other features for which data is stored and processed
          locally on your device, and we do not have any access to that
          information.{" "}
        </p>
      </div>
      <div className={classes.item}>
        <h2>5. How and Why We Use Your Data. Legal Bases for Processing</h2>
        <p>
          All processing of your data must be lawful, i.e. reliant upon one of
          the lawful bases referenced under applicable law, namely:
        </p>
        <ul>
          <li>
            Performance of a contract with you, i.e. provision of the Product as
            described in our Terms of Use,
          </li>
          <li>
            Our legitimate interests, i.e. when we believe that we can use
            and/or share your data since we have a legitimate balanced reason to
            do so,
          </li>
          <li>Performance of our obligations under the applicable law.</li>
        </ul>
        <p>
          Below you can see described the purposes of our processing (why we
          process your information) for each lawful basis and the corresponding
          data categories used in each case.
        </p>
        <p>
          As mentioned earlier, your user journey may vary depending on whether
          it is the App or the Website (or both) you have chosen to use.
          However, the purposes and legal bases for data processing in relation
          to the Website and the App coincide.
        </p>
        <table className={classes.table}>
          <caption>Performance of a contract with you</caption>
          <thead>
            <tr>
              <th>Why and how we process your information</th>
              <th>Data Used</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  To ensure the intended operation of the Product, namely, to
                  maintain your profile in the Product, ensure you have easy
                  access to it, and provide you with the functionality of the
                  Product provided for in the Product descriptions as well as
                  included materials. For example, we use your data to create
                  fitness plans best suited for you.
                </p>
              </td>
              <td className={classes.underline}>
                <p>General Information</p>
                <p>Fitness and Wellness Onboarding Information</p>
                <p>Device-Related Information</p>
                <p>Usage Information</p>
                <p>Your User Choices</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  For billing purposes, account management, and feature
                  accessibility.
                </p>
              </td>
              <td className={classes.underline}>
                <p>General Information</p>
                <p>Device-Related Information</p>
                <p>Payment Data/Subscription Status</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Please note that provision of the information above is necessary for
          us to provide our Product to you (perform our contract with you). We
          will not be able to provide the Product without the information listed
          above. We will also not be able to continue providing the Product if
          you request data erasure or otherwise restrict our access to such
          data.
        </p>
        <table className={classes.table}>
          <caption>Our legitimate interest</caption>
          <thead>
            <tr>
              <th>Why and how we process your information</th>
              <th>Substantiation of Legitimate Interest</th>
              <th>Data Used</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  To analyze the performance of our Product, improve it, and
                  further deliver a better user experience based on analysis of
                  your interaction with the Product.
                </p>
                <p>For example:</p>
                <ul>
                  <li>
                    We count users and events for reporting to identify how many
                    users the Product has. This includes metrics like how many
                    unique users the Product has.
                  </li>
                  <li>We analyze bugs and fix issues with the Product.</li>
                </ul>
              </td>
              <td>
                <p>
                  Our interest is to measure the use of our Product and count
                  the people who interact with it. Such actions are aimed at
                  improving Product development and enabling the provision of
                  accurate and reliable internal reporting.
                </p>
              </td>
              <td className={classes.underline}>
                <p>Usage Information</p>
                <p>Device-Related Information</p>
                <p>Log and Troubleshooting Information</p>
                <p>Payment Data (the fact of purchase)/Subscription Status</p>
                <p>Feedback Information</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To assess the effectiveness of our advertising campaigns by
                  understanding which advertisements led you to the Product and
                  whether you finally used the Product or purchased it/some of
                  its features.
                </p>
              </td>
              <td>
                It is our interest to understand who may be interested in our
                Product and to offer our Product to new potentially interested
                audiences as well as remind old users about it.
              </td>
              <td className={classes.underline}>
                <p>General Information (in a limited scope)</p>
                <p>Device-Related Information</p>
                <p>Payment Data (the fact of purchase)/Subscription Status</p>
                <p>Advertising Networks Information</p>
              </td>
            </tr>
            <td>
              <p>To communicate with you in various ways, e.g.:</p>
              <ul>
                <li>
                  to send you technical notices, updates, and security alerts;
                </li>
                <li>
                  to send reminders to your smartphone or web browser via in-App
                  messages and in-App and web push notifications;
                </li>
                <li>
                  to send promotional communications, such as product updates,
                  offers, discounts, and to provide news and information that we
                  think will be of interest to you.
                </li>
              </ul>
              <p>
                You can opt out of any of our promotional communications anytime
                as described in your privacy rights section.
              </p>
            </td>
            <td>
              <p>
                We believe the provision of presumably relevant information will
                be a plus in addition to the provision of the Product
                functionality.
              </p>
            </td>
            <td className={classes.underline}>
              <p>General Information (in a limited scope)</p>
              <p>Your User Choices</p>
              <p>Usage Information</p>
              <p>Device-Related Information</p>
            </td>
            <tr>
              <td>
                <p>
                  To provide customer support/ request processing to the
                  customer support team. This includes, for example:
                </p>
                <ul>
                  <li>
                    responding when you request our help such as assisting with
                    your account login or verification process;
                  </li>
                  <li>addressing your comments and questions.</li>
                </ul>
              </td>
              <td>
                It is in our interest to assist our users with any inquiries or
                requests.
              </td>
              <td className={classes.underline}>
                <p>General Information</p>
                <p>Usage Information</p>
                <p>Device-Related Information</p>
                <p>Log and Troubleshooting Information</p>
                <p>Payment Data</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To defend ourselves from prospective legal claims, litigation,
                  or other disputes, including in relation to violations of the
                  Terms of Use, Privacy Notice, Subscription Policy, and/or
                  Refund Policy.{" "}
                </p>
                <p>
                  To ensure the safety, security, and integrity of the Product.{" "}
                </p>
                <p>To provide information to the authorities upon request.</p>
              </td>
              <td>
                <p>
                  It is in our interest to respond to complaints and prevent and
                  address fraud, unauthorized use of the Product, violations of
                  our Terms of Use and/or other policies, or other harmful or
                  illegal activity.
                </p>
                <p>
                  It is in our interest to seek legal advice and protect
                  ourselves (including our rights, personnel, property, or
                  products), and our users or others, including as part of
                  investigations or regulatory inquiries and litigation or other
                  disputes.
                </p>
              </td>
              <td className={classes.underline}>
                <p>General Information </p>
                <p>Usage Information</p>
                <p>Device-Related Information</p>
                <p>Log and Troubleshooting Information</p>
                <p>Payment Data</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To identify disruptions and maintain Product security,
                  including the detection and tracking of inadmissible access
                  attempts and access to our servers.
                </p>
              </td>
              <td>
                <p>
                  It is in our interest to eliminate disruptions, maintain
                  system security, and detect and track inadmissible access and
                  access attempts.
                </p>
              </td>
              <td className={classes.underline}>
                <p>General Information </p>
                <p>Usage Information</p>
                <p>Device-Related Information</p>
                <p>Log and Troubleshooting Information</p>
                <p>Payment Data/Subscription Status</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table className={classes.table}>
          <caption>Performance of a contract with you</caption>
          <thead>
            <tr>
              <th>Why and how we process your information</th>
              <th>Data Used</th>
            </tr>
          </thead>
          <tbody>
            <td>
              <p>
                According to applicable tax and accounting laws, we may need to
                report your payments to conduct any necessary statutory
                reports/withdrawals.
              </p>
              <p>
                Further, sometimes in order to comply with applicable laws, we
                need to authenticate you, i.e. collect your identification
                information.{" "}
              </p>
              <p>
                Finally, according to applicable law, we need to understand
                whether you allow the processing of your data, push
                notifications, marketing communications, and similar activities.{" "}
              </p>
              <p>To provide information to authorities upon request.</p>
            </td>
            <td className={classes.underline}>
              <p>Payment Data</p>
              <p>General Information</p>
              <p>Usage Information</p>
              <p>Your User Choices</p>
            </td>
          </tbody>
        </table>
        <h3>Automated processing of your data</h3>
        <p>
          We do not use your data to make decisions based solely on automated
          processing that would create legal or comparably significant effects.
        </p>
      </div>
      <div className={classes.item}>
        <h2>6. Cookies and Similar Technologies, Your Choices</h2>

        <p>
          Cookies (<strong>“Cookies”</strong> or <strong>“Cookie”</strong>) are
          small text files that are placed on your device while you browse our
          Website. They help us remember/recognize some of your actions or
          choices during Website use for various purposes. Sometimes, they help
          us track you through websites and devices to understand the
          effectiveness of our ads.
        </p>
        <p>
          We use similar technologies (<strong>“Similar Technologies”</strong>)
          which are technically different but serve the same purpose of
          remembering/recognizing your actions during App or Website use.
          Similar Technologies include:
        </p>
        <ul>
          <li>
            Pixels/Web beacons/Tags - code snippets added to our Website, for
            example, to create a tiny graphic. We usually use pixels that are
            dependent on cookies so your Cookie choices may limit the
            performance of pixels.
          </li>
          <li>
            Local storage - a property that allows us to save information in
            your web browser with no expiration date (even after the browser
            window is closed).
          </li>
        </ul>
        <h3>Who sets Cookies or Similar Technologies on our Website </h3>
        <p>
          There are two main types of Cookies and Similar Technologies that we
          can set:
        </p>
        <ul>
          <li>
            First-party Cookies and Similar Technologies: these Cookies and
            Similar Technologies are placed and read by us directly;{" "}
          </li>
          <li>
            Third-party Cookies: these Cookies are not set by us, but by our
            partners, like payment service providers (e.g., PayPal or Braintree)
            or advertising networks.
          </li>
        </ul>
        <h3>Why and how we use Cookies and Similar Technologies</h3>
        <p>
          We use Cookies and Similar Technologies for a number of reasons, such
          as:
        </p>
        <ul>
          <li>protecting your account, </li>
          <li>
            saving your onboarding experience so that you can return to exactly
            where you left off,
          </li>
          <li>analyzing Website performance, </li>
          <li>advertising our Products,</li>
          <li>assessing advertising campaign effectiveness, and </li>
          <li>generally trying to make your experience with us better.</li>
        </ul>
        <p>
          The Cookies and Similar Technologies we use generally fall into one of
          the following categories, whereas each category description allows us
          to understand why those Cookies and Similar Technologies are
          necessary.
        </p>
        <h3>Strictly Necessary Cookies and Similar Technologies</h3>
        <p>
          These Cookies and Similar Technologies are necessary for the Website
          to function and cannot be switched off in our systems.{" "}
        </p>
        <p>
          They are set out in response to actions you make, mainly in order to
          remember your choices, including cookie preferences, onboarding
          completion status and values, and login data.
        </p>
        <p>
          If you block strictly necessary Cookies, some parts of the Website
          will not work.
        </p>
        <h3>Performance (Analytics) Cookies</h3>
        <p>
          These Cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our Website. They help us know
          which pages are the most and least popular and see how the user moves
          across the Website.{" "}
        </p>
        <h3>Functional (Preference) Cookies</h3>
        <p>
          Preference Cookies are used to remember your preferences and to
          recognize you when you return to our Product.
        </p>
        <p>
          If you do not allow these Cookies then some or all of the below
          services may not function properly.
        </p>
        <h3>Targeting (Tracking) Cookies and Similar Technologies</h3>
        <p>
          We and our service providers (advertising networks) use Cookies and
          Similar Technologies to direct our ads to you through targeted
          advertisements for our Product on other sites you visit and to measure
          your engagement with those ads.{" "}
        </p>
        <p>
          These Cookies and Similar Technologies may be used by those
          advertising networks to build a profile of your interests and show you
          relevant ads on other sites.
        </p>
        <h3>Your Cookie choices</h3>
        <p>
          Most browsers allow you to manage how Cookies are set and used as
          you’re browsing, and to clear Cookies and browsing data. Typically,
          such information can be found under the browser’s ‘Help’,
          ‘Preferences’, or ‘Options’ menus. Also, your browser may have
          settings allowing you to manage Cookies on a site-by-site basis as
          well as provide for incognito mode.
        </p>
        <p className={`${classes.marginLeft} ${classes.underline}`}>
          Cookie settings in Chrome
          <br />
          Cookie settings in Firefox
          <br />
          Cookie settings in Safari - iOS
          <br />
          Cookie settings in Safari - macOS
          <br />
          Cookie settings in Opera
          <br />
        </p>
        <h3>Your Similar Technologies choices</h3>
        <p>
          Most mobile devices and applications allow you to manage how other
          technologies, such as unique identifiers used to identify a browser,
          app, or device, are set and used. For example, the Advertising ID on
          Android devices or Apple’s Advertising Identifier can be managed in
          your device’s settings, while app-specific identifiers may typically
          be managed in the app’s settings.
        </p>
        <h3>Opt out of Personalized Advertising</h3>
        <p>
          You can opt out of advertising provided by particular networks both in
          the App and on the Website:
        </p>
        <p className={`${classes.marginLeft} ${classes.underline}`}>
          Google Ad Settings/Ad Personalisation <br />
          Facebook Ad Settings <br />
          Instagram Opt-Out Instruction <br />
          Tik-Tok Opt-Out Instruction <br />
          Twitter Opt-Out Instruction <br />
          Pinterest Opt-Out Instruction <br />
          Outbrain opt out on desktop and mobile web (Section 3 → Outbrain User
          Types, including Opt Out Options) <br />
          Taboola Access to Data and Opt-Out Tool.
        </p>
        <p>
          Please note that if you opt out of personalized advertising, you will
          still see advertisements – they will just not be tailored to your
          interests. Also note that deleting browser Cookies may remove the
          Cookie preferences you already made, so you may need to opt out again
          in the future.
        </p>
        <p>
          Finally, you can exercise your preferences and opt out of personalized
          advertisements with regard to your mobile devices (smartphones,
          tablets) by:
        </p>
        <p className={classes.marginLeft}>
          updating your mobile device settings: <br />
          IOS devices: Settings → Privacy → Tracking → Allow Apps to Request to
          Track (uncheck); <br /> Android Devices → Settings → Google → Ads →
          Opt Out of Ads Personalization.
        </p>
        <h3>Do Not Track</h3>
        <p>
          "Do Not Track" is a preference you can set in your web browser to let
          the websites you visit know that you do not want them collecting
          information about you. The Website does not currently respond to a "Do
          Not Track" or similar signal.
        </p>
      </div>

      <div className={classes.item}>
        <h2>7. Personal Data Transfers</h2>
        <h2>7.1. Data Transfers by Both the Website and the App </h2>
        <p>
          As we mentioned earlier, this Privacy Notice is aimed at describing
          data use in relation to the Product. Below, we explain with whom we
          share your personal data from the Product, i.e. both the Website and
          the App.
        </p>
        <h3>Third-party service providers</h3>
        <p>
          A number of Product functions rely upon services from third parties,
          so-called ‘processors’. Processors act based on and in accordance with
          our requests and shall not use your data for their own purposes.{" "}
        </p>
        <p>
          The main service providers the Product shares your data with are as
          follows:
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Amazon Web Services (Amazon Web Services EMEA SARL, 352 2789 0057,
            38 Avenue John F. Kennedy, L-1855, Luxembourg)
          </strong>
        </p>
        <p>
          We use Amazon Web Services, which is a hosting and backend service
          provided by Amazon, for personal data hosting and enabling our Product
          to operate and be distributed to its users.
        </p>
        <p className={classes.marginLeft}>
          <strong>
            AppsFlyer (AppsFlyer Ltd., 14 Maskit Str., Floor 6 POB 12371,
            Herzliya 46733149, Israel)
          </strong>
        </p>
        <p>
          Appsflyer helps us understand, in particular, how users find our
          Product (for example, which advertiser delivered an ad that led you to
          our Product). Appsflyer also provides us with different analytics
          tools that enable us to research, analyze, and make your use of the
          Product better.
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Amplitude (Amplitude Inc., 201 3rd Street, Suite 200, San Francisco,
            CA 94103)
          </strong>
        </p>
        <p>
          We use Amplitude for analytics and customer support purposes,
          specifically to understand how customers use our App. As for analytics
          purposes, Amplitude provides us with different tools that enable us to
          research and analyze Your use of the Service, which helps us to decide
          what features we should focus on more. For customer support purposes,
          Amplitude helps us to respond to Your requests for technical support.
          For example, it allows us to track Your interaction with our App and
          in order to detect particular technical issues with the App.
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Google Analytics / Google Analytics for Firebase (Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Ireland)
          </strong>
        </p>
        <p>
          Google Analytics / Google Analytics for Firebase are product analytics
          tools provided by Google, which help us to analyze the Service data
          and understand our users to optimize the Service performance.
        </p>

        <p className={classes.marginLeft}>
          <strong>SendPulse (SendPulse Inc., 220 E 23rd St)</strong>
        </p>
        <p>
          We may transfer, among other information, your email address, phone
          number, device information, payment status, and subscription date to
          SendPulse in order to deliver customized emails, and web push
          notifications.{" "}
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Reteno (Retention Yes Sp. Z.O.O., 02-972, Poland, voivodeship
            Mazovetsky, Warsaw city, Adama Branickiego Street, No. 21)
          </strong>
        </p>
        <p>
          We may transfer, among other information, your email address, phone
          number, device information, payment status, and subscription date to
          Reteno in order to deliver customized emails, web push notifications
          and other communications.
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Front (FrontApp, Inc., 1455 Market Street, 19th Floor, San
            Francisco, CA 94103)
          </strong>
        </p>
        <p>
          In order to provide you with swift and smooth customer support, we use
          Front - software that allows us to optimize conversations between
          users and customer support agents. In this regard, Front receives and
          stores copies of all data related to your customer support requests,
          such as emails, purchase details, feedback, etc.
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Customer support agents <br />
            People and companies providing us with professional app development
            and maintenance services
          </strong>
        </p>
        <h3>Advertising networks </h3>
        <p>
          We use the services of third-party advertising platforms, for example,
          Instagram, Facebook, and Snapchat, which allows us to show targeted
          campaigns and messages to users within their platforms based on the
          user’s behavior. To put it plainly, we provide information about you
          that will allow advertising platforms to locate you in their database
          and information that you performed a desirable action, such as making
          a purchase.
        </p>
        <p>
          This allows an advertising network to understand which people are
          interested in our Product as well as to display advertisements of the
          Product to users who will most likely want it.
        </p>
        <p>
          Please note that most advertising networks act as separate controllers
          or co-controllers of your data in full or in part, which means that
          such controllers decide whether to use the transferred data for their
          own purposes, e.g. providing you with advertisements of other
          providers similar to our Product. You can learn more about the data
          processing practices of such companies in their respective privacy
          policies:
        </p>
        <p className={classes.marginLeft}>
          Facebook{" "}
          <a href="https://www.facebook.com/privacy/policy/">
            {" "}
            https://www.facebook.com/privacy/policy/{" "}
          </a>
          <br />
          Instagram{" "}
          <a href="https://help.instagram.com/155833707900388">
            {" "}
            https://help.instagram.com/155833707900388{" "}
          </a>
          <br />
          Snapchat
          <a href=" https://snap.com/en-US/privacy/privacy-policy">
            {" "}
            https://snap.com/en-US/privacy/privacy-policy{" "}
          </a>
        </p>
        <p>
          We do not share any health & wellness information with advertising
          networks.
        </p>
        <p>
          Should you wish to opt out of personalized advertising, please explore
          the options described in Section 6 above.{" "}
        </p>
        <h3>Related companies </h3>
        <p>
          We share your information with our affiliates, i.e. companies which
          are related to Straiton Limited, by having the same owner, for
          example. Where such sharing takes place, related companies will honor
          this Privacy Notice.
        </p>
        <h3>Public authorities and bodies</h3>
        <p>
          We may use and disclose personal data to enforce our Terms of Use to
          protect our rights, privacy, safety, or property, and/or that of our
          affiliates, you, or others, and to respond to requests from courts,
          law enforcement agencies, regulatory agencies, and other public and
          government authorities, or in other cases provided for by applicable
          law.
        </p>
        <h3>International data transfers</h3>
        <p>
          Please note that in the course of doing business, we transfer your
          personal data outside of EU/EEA countries (if you are located outside
          of EU/EEA countries, we may transfer your personal data to other
          jurisdictions). For example, some of our service providers are located
          in the United States of America.{" "}
        </p>
        <p>
          We shall implement measures as established under applicable law to
          ensure data transfers are protected as required. When a recipient is
          located in one of the jurisdictions listed on the official website of
          the European Commission here, the appropriate safeguard is the
          corresponding adequacy decision of the Commission. Where no such
          adequacy decision is present, we rely on the so-called Standard
          Contractual Clauses (see more about them here), along with a thorough
          analysis of the law of the destination country to safeguard your data
          and your rights.
        </p>
      </div>

      <div className={classes.item}>
        <h2>7.2. App-Specific Data Transfers</h2>
        <p>
          Finally, on top of what has been described for both the Website and
          the App, the App itself shares data as follows.
        </p>
        <h3>Third-party service providers</h3>
        <p className={classes.marginLeft}>
          <strong>
            Firebase Crashlytics (Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Ireland)
          </strong>
        </p>
        <p>
          Firebase Crashlytics is a Google Firebase service that allows us to
          track application performance, i.e. we can discover the causes and
          severity of crashes in the Product.{" "}
        </p>
        <p className={classes.marginLeft}>
          <strong>
            Firebase Remote Config (Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Ireland)
          </strong>
        </p>
        <p>
          Firebase Remote Config allows us to introduce changes to the App’s
          behavior and appearance on the fly.{" "}
        </p>
      </div>
      <div className={classes.item}>
        <h2>8. Retention of Your Personal Data</h2>
        <p>
          We keep your personal data for as long as necessary to fulfill the
          purposes outlined in this Privacy Notice unless further retention is
          required under applicable law.
        </p>
        <p>
          You can request to terminate your account and delete your data at any
          time. We treat such requests as a termination of your contract with us
          and as a revocation of your consent(s). We will accommodate such
          requests and terminate any data processing under respective legal
          bases.{" "}
        </p>
        <p>
          Please note that certain data can be retained even after your deletion
          request. That may be the case where processing is based upon our
          legitimate interest. For example, we may keep information on purchases
          in order to protect our rights in a legal action. Such storage is not
          indefinite and is limited to such data's usefulness and achieving our
          legitimate goals. As described in the Your Privacy Rights and How to
          Exercise Them section, you may object to our legitimate interests. If
          you wish to do so, please indicate this specifically in your message
          to us.
        </p>
        <p>
          We may also keep data if deletion is not technically possible (for
          example, because your personal information has been stored in backup
          archives). We will securely store your personal information and
          isolate it from any further processing until deletion is possible.{" "}
        </p>
        <p>
          The same applies to cases where we are obliged to retain your data
          under applicable law (e.g. accounting and tax laws). In the event of
          such retention, we will ensure via technical and organizational means
          that such data is not used for conflicting purposes, for example,
          advertising to you.
        </p>
      </div>
      <div className={classes.item}>
        <h2>9. Changes to this Privacy Notice</h2>
        <p>
          We may change this Privacy Notice from time to time by posting those
          changes directly on this webpage. Please make sure to review this
          Privacy Notice regularly. Should the change be major, we will try our
          best to draw your attention to it, e.g. via a pop-up or email. By
          continuing to use our Product, you acknowledge you have read and
          understood the most up-to-date Privacy Notice.
        </p>
      </div>
    </TermsWrapper>
  );
};
