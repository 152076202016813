// Libs
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Components
import BottomSheetContainer from "./BottomSheet";
import Advices from "./Advices";

// Assets
import startButton from "../../assets/startButton.svg";

// Utils
import getUserCurrentProgress from "../../utils/getUserCurrentProgress";

import classes from "./styles.module.scss";
import "react-circular-progressbar/dist/styles.css";
import { ProfileLayout } from "./layout";
import {
  getTrainingProgress,
  getTrainings,
} from "../../redux/slices/exerciseSlice";
import { axiosInstance } from "../../api";

const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allExercieses, userDaysProgress, isLoading } = useSelector(
    (state) => state.exerciseSlice
  );

  const [activeDayIndex, setActiveDayIndex] = useState();
  const [activeDay, setActiveDay] = useState();
  const [exerciseAdvice, setExerciseAdvice] = useState();

  const [openAdvicesBottomSheet, setOpenAdvicesBottomSheet] = useState(false);

  const progress = getUserCurrentProgress(userDaysProgress, allExercieses);

  useEffect(() => {
    if (userDaysProgress > allExercieses.length) {
      setActiveDayIndex(allExercieses?.length);
      return;
    }
    setActiveDayIndex(userDaysProgress);
  }, [userDaysProgress]);

  useEffect(() => {
    if (userDaysProgress > allExercieses.length) {
      setActiveDay(allExercieses?.[allExercieses.length - 1]);
      return;
    }
    setActiveDay(allExercieses[userDaysProgress - 1]);
  }, [userDaysProgress, allExercieses]);

  useEffect(() => {
    dispatch(getTrainingProgress());
    dispatch(getTrainings());
    const fetchSubscription = async () => {
      const res = axiosInstance.get("/me/subscriptions/active");
      console.log(res.data);
    };
    fetchSubscription();
  }, []);

  const isCompleted = userDaysProgress > activeDayIndex;

  console.log({ activeDay });

  return (
    <ProfileLayout title="Workout plan">
      <main className={classes.main}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className={classes.progressBarWrapper}>
              <CircularProgressbar
                strokeWidth={16}
                className={classes.progressBarCircle}
                styles={buildStyles({
                  pathColor: "var(--primary)",
                })}
                value={progress}
              />
              Your current progress is {progress} %
            </div>
            <div
              className={
                isCompleted
                  ? `${classes.preview} ${classes.completed}`
                  : `${classes.preview}`
              }
            >
              {isCompleted && <span>Done!</span>}
              <img
                src={
                  activeDay?.exercises[0].previewPicture ||
                  "https://via.assets.so/img.jpg?w=612&h=343&tc=#d4d4d4&bg=#f6f6f6"
                }
                alt="preview"
              />
            </div>
            <button
              className={classes.startButton}
              onClick={() => {
                navigate(`/day-exercises/${activeDayIndex}`);
              }}
            >
              <img src={startButton} alt="" />
              <p>
                {userDaysProgress > activeDayIndex ? "Repeat" : "Start"} day{" "}
                {activeDayIndex}
              </p>
            </button>
            <div className={classes.daysWrapper}>
              {allExercieses.map((workout, index) => {
                return (
                  <div
                    onClick={() => {
                      setActiveDay(workout);
                      setActiveDayIndex(index + 1);
                    }}
                    key={index}
                    className={
                      index + 1 === activeDayIndex
                        ? `${classes.dayItem} ${classes.active}`
                        : `${classes.dayItem} ${classes.complete}`
                    }
                  >
                    {workout.name}
                  </div>
                );
              })}
            </div>
            <div className={classes.contentWrapper}>
              {activeDay?.exercises?.map((exercise) => {
                return (
                  <div
                    key={Math.random()}
                    className={classes.contentItem}
                    onClick={() => {
                      setExerciseAdvice(exercise);
                      setOpenAdvicesBottomSheet(true);
                    }}
                  >
                    <img src={exercise.previewPicture} alt="preview" />
                    <div className={classes.infoWrapper}>
                      <p className={classes.contentTitle}>{exercise.name}</p>
                      <p className={classes.contentSubtitle}>
                        {exercise.subTitle}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <BottomSheetContainer
          open={openAdvicesBottomSheet}
          setOpen={setOpenAdvicesBottomSheet}
        >
          <Advices
            steps={exerciseAdvice?.steps}
            videos={[exerciseAdvice?.video]}
          />
        </BottomSheetContainer>
      </main>
    </ProfileLayout>
  );
};

export default ProfilePage;
