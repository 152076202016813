import React from "react";
import styles from "./styles.module.scss";

export const Reviews = () => {
  const reviewsData = [
    {
      name: "Johnson E.",
      text: "I've been using this app for a couple of weeks now, and I'm already seeing results! The workouts are diverse and manageable for all fitness levels. Definitely recommend it for anyone looking to get fit and stay motivated.",
    },
    {
      name: "Williams O.",
      text: "As a full-time employee and a mom, finding time for workouts can be tough. This app has been a game-changer! The personalization and flexibility in exercise types make it easy to stay consistent.",
    },
    {
      name: "Davis M.",
      text: "With this program I've seen significant gains in my strength and muscle definition. It's like having your own personal coach available 24/7 and catering to all your needs!",
    },
  ];
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Users love our plans</h2>
      {reviewsData.map((review, index) => (
        <div key={index} className={styles.review}>
          <div className={styles.name}>{review.name}</div>
          <div className={styles.stars}>⭐⭐⭐⭐⭐</div>
          <div className={styles.paragraph}>{review.text}</div>
        </div>
      ))}
    </div>
  );
};
