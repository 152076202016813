import { IconCard } from "../../../../../common/iconCard";
import { Devices, Night, Paint, Rest } from "./icons";
import styles from "./styles.module.scss";

const DATA = {
  NINE_TO_FIVE: {
    title: "9 to 5",
    icon: Devices,
  },
  NIGHT_SHIFTS: {
    title: "Night shifts",
    icon: Night,
  },
  FLEXIBLE_HOURS: {
    title: "Flexible hours",
    icon: Paint,
  },
  NOT_WORKING: {
    title: "I'm retired/not working right now",
    icon: Rest,
  },
};

export const WorkLife = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
  };

  return (
    <div className={styles.container}>
      <h2>How does your work life look like?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, { title, icon: Icon }]) => (
          <IconCard
            onClick={() => handleNextStep(key)}
            img={<Icon />}
            title={title}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
