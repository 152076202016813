import styles from "./styles.module.scss";
import img from "./img.png";
import { useSelector } from "react-redux";

export const ProfileCard = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const questions = userInfo?.questions;
  const fitnessProfile = userInfo?.fitnessProfile;

  return (
    <div className={styles.profileCard}>
      <h2>This plan is based on your needs and parameters:</h2>
      <div className={styles.profileChart}>
        <div className={styles.clouds}>
          <div className={styles.cloud}>
            <p>Level</p> <h5>{fitnessProfile?.fitnessLevel}</h5>
          </div>
          <div className={styles.cloud}>
            <p>Body type</p> <h5>{fitnessProfile?.bodyType}</h5>
          </div>
          <div className={styles.cloud}>
            <p>Age</p> <h5>{questions?.qqAge}</h5>
          </div>
        </div>
        <div className={styles.goal}>
          <div className={styles.goalCloud}>Reduce stress and anxiety</div>
        </div>
      </div>
    </div>
  );
};
