import React from "react";
import styles from "./styles.module.scss";
import { FAQItem } from "./item";

export const FAQSection = () => {
  const faqData = [
    {
      question: "How will this program help me achieve my goal?",
      answer:
        "Your plan is custom to you, your needs and your fitness level. We include diverse sets of exercises to target every muscle group you selected, gradually increase intensity and encourage you to stay motivated.",
    },
    {
      question: "How long will it take to see results?",
      answer:
        "Results vary depending on individual factors such as starting fitness level, diet, and consistency. Generally, noticeable improvements can be seen in a few weeks.",
    },
    {
      question: "Where do I find my program?",
      answer:
        "After the purchase your plan and all the workout sets will become available to you. Everything is already divided by day and you can start your journey at any moment!",
    },
  ];

  return (
    <div className={styles.faqSection}>
      <h2 className={styles.title}>People often ask</h2>
      {faqData.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          defaultExpanded={index === 0}
        />
      ))}
    </div>
  );
};
