import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

const initialState = {
  plans: [],
  activePlan: null,
  isLoading: false,
};

export const getPlansThunk = createAsyncThunk("plans/getPlans", async () => {
  try {
    const res = await axiosInstance.get("/subscription-plans");

    const { items } = res.data;

    const initialActivePlanIndex = items.findIndex(
      (plan) => plan?.uiDisplayOptions.title
    );
    const initialActivePlan =
      initialActivePlanIndex !== -1 ? items[initialActivePlanIndex] : items[0];

    return { items, initialActivePlan };
  } catch (error) {
    console.log(error);
  }
});

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setActivePlan(state, { payload }) {
      state.activePlan = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlansThunk.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(getPlansThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.plans = payload.items;
          state.activePlan = payload.initialActivePlan;
        }
        state.isLoading = false;
      });
  },
});

export const reducer = plansSlice.reducer;

export const { reset, setActivePlan } = plansSlice.actions;
