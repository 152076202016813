import Overweight from "./img/overweight.png";
import Underweight from "./img/underweight.png";
import Normal from "./img/normal.png";
import Obese from "./img/obese.png";
import After from "./img/after.png";
import styles from "./styles.module.scss";
import { Loader } from "../../../../../common/loader";
import { useGetFitnessProfile } from "../../../../../hooks/useGetFitnessProfile";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
};
export const Transform = () => {
  const { profile } = useGetFitnessProfile();

  const getFilledIndicatorsCount = (level) => {
    switch (level) {
      case "Beginner":
        return 1;
      case "Intermediate":
        return 2;
      case "Advanced":
        return 3;
      default:
        return 0;
    }
  };

  if (!profile) {
    return <Loader />;
  }

  const { bodyMassType, fitnessLevel } = profile;

  const filledCount = getFilledIndicatorsCount(fitnessLevel);

  return (
    <div className={styles.container}>
      <div className={styles.transform}>
        <div className={styles.header}>
          <div className={styles.headerItem}>Now</div>
          <div className={styles.headerDivider}></div>
          <div className={styles.headerItem}>
            <span>With EasyFit</span>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.imageWrapper}>
            <img
              src={BMI_TYPE_IMAGES[bodyMassType] || Obese}
              alt="Before"
              className={styles.comparisonImage}
            />
            <img src={After} alt="After" className={styles.comparisonImage} />
          </div>
        </div>
      </div>
      <div className={styles.cards}>
        <div className={styles.cardsWrapper}>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Body fat</p>
              <p className={styles.value}>{bodyMassType}</p>
            </div>
          </div>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Pilates level</p>
              <p className={styles.value}>{fitnessLevel}</p>
            </div>
            <div className={styles.levelIndicators}>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className={`${styles.indicator} ${
                    index < filledCount ? styles.filled : ""
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.cardsWrapper}>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Body fat</p>
              <p className={styles.value}>Normal</p>
            </div>
          </div>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Pilates level</p>
              <p className={styles.value}>Advanced</p>
            </div>
            <div className={styles.levelIndicators}>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className={`${styles.indicator} ${styles.filled}`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
