import { Outlet, useParams } from "react-router";
import { OnboardingLayout } from "../../../common";
import { ProgressBar } from "./progressBar";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserInfoThunk } from "../../../redux/slices/userSlice";

export const OnboardingStepper = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { title } = useParams();

  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    setShowProgressBar(!document.getElementById("breakdown"));
  }, [params]);

  useEffect(() => {
    if (localStorage.getItem("token")) dispatch(getUserInfoThunk());
  }, []);

  return (
    <OnboardingLayout
      hideNavigateBack={params.get("q") === "1" && title === "profile"}
      headerContent={showProgressBar ? <ProgressBar /> : undefined}
    >
      <Outlet />
    </OnboardingLayout>
  );
};
