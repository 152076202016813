export const getPlanDuration = (duration) => {
  switch (duration) {
    case 7:
      return "1-week";
    case 28:
      return "4-week";
    case 84:
      return "12-week";
    default:
      return `${duration}-days`;
  }
};

export const getAgreementText = (plan, isDiscounted) => {
  const { priceNew, priceOld, duration } = getPlanPricing(plan);
  const durationText = getPlanDuration(duration);

  return (
    <>
      Without cancellation, before the selected discounted intro plan ends, I
      accept that EasyFit will automatically charge ${priceOld} every{" "}
      {durationText} until I cancel. Cancel online via the account page on the
      website or app.
    </>
  );
};

export const getPlanPricing = (plan) => {
  return {
    title: plan?.title,
    popular: plan?.uiDisplayOptions?.title,
    priceNew: (plan?.priceAmount / 100).toFixed(2),
    priceOld: (plan?.noDiscountPriceAmount / 100).toFixed(2),
    perDay: (plan?.dailyPriceAmount / 100).toFixed(2),
    perDayOld: (plan?.noDiscountDailyPriceAmount / 100).toFixed(2),
    currency: plan?.currency || "USD",
    duration: plan?.billingPeriodDuration,
  };
};
