import React, { useState } from "react";
import Slider from "react-slick";
import styles from "./styles.module.scss";
import amanda from "./slider/img/amanda.png";
import emily from "./slider/img/emily.png";
import lisa from "./slider/img/lisa.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderData = [
  {
    image: amanda,
    name: "Amanda",
    weightChange: "-8 kg",
    text: " I've struggled with my weight for years, but with support from the app I felt inspired. The tailored workout plans and easy-to-follow exercises kept me motivated and on track. I'm thrilled with my results and feel more confident and healthier than ever!",
  },
  {
    image: emily,
    name: "Emily",
    weightChange: "-7 kg",
    text: "The variety of workouts, from yoga to higher intensity, keeps things interesting and fun. I've seen improvements in my stamina, flexibility, and overall health.",
  },
  {
    image: lisa,
    name: "Lisa",
    weightChange: "-13 kg",
    text: "The guided exercises and stress-relief workouts are fantastic. I feel more balanced and less stressed since incorporating these routines.",
  },
];

const CustomNextArrow = ({ onClick }) => (
  <div className={styles.slickNext} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        transform="matrix(-1 0 0 1 32 0)"
        fill="white"
      />
      <path
        d="M9.98828 15.9928C9.98828 15.4408 10.4353 14.9938 10.9873 14.9938C11.4653 14.9938 17.0523 14.9938 18.5423 14.9938L15.5763 11.9968L16.9813 10.5918L21.6953 15.2748C21.8913 15.4698 21.9883 15.7318 21.9883 15.9938C21.9883 16.2548 21.8903 16.5158 21.6953 16.7108L16.9813 21.3938L15.5763 19.9888L18.5423 16.9918C17.0523 16.9918 11.4653 16.9918 10.9873 16.9918C10.4353 16.9918 9.98828 16.5448 9.98828 15.9928Z"
        fill="black"
      />
    </svg>
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div className={styles.slickPrev} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M22.0117 15.9928C22.0117 15.4408 21.5647 14.9938 21.0127 14.9938C20.5347 14.9938 14.9477 14.9938 13.4577 14.9938L16.4237 11.9968L15.0187 10.5918L10.3047 15.2748C10.1087 15.4698 10.0117 15.7318 10.0117 15.9938C10.0117 16.2548 10.1097 16.5158 10.3047 16.7108L15.0187 21.3938L16.4237 19.9888L13.4577 16.9918C14.9477 16.9918 20.5347 16.9918 21.0127 16.9918C21.5647 16.9918 22.0117 16.5448 22.0117 15.9928Z"
        fill="black"
      />
    </svg>
  </div>
);

export const ResultsSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const truncateText = (text, limit) => {
    if (text.length <= limit) return text;

    const words = text.split(" ");
    let truncatedText = "";

    for (let word of words) {
      if (truncatedText.length + word.length + 1 > limit) break;
      truncatedText += `${word} `;
    }

    return truncatedText.trim() + "...";
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      setIsExpanded(false);
    },
  };

  const { name, weightChange, text } = sliderData[currentSlide];
  const textLimit = 149;

  return (
    <div className={styles.container}>
      <h2>Results that make us proud</h2>
      <div className={styles.sliderWrapper}>
        <Slider {...settings}>
          {sliderData.map((slide, index) => (
            <div key={index} className={styles.slide}>
              <img src={slide.image} alt="Before and After" />
            </div>
          ))}
        </Slider>
        <div className={styles.description}>
          <p>
            <strong>{name}</strong>,
            <span className={styles.weightChange}> {weightChange}</span>
          </p>
          <p className={styles.story}>
            {isExpanded || text.length <= textLimit
              ? text
              : truncateText(text, textLimit)}
          </p>
          {text.length > textLimit && (
            <span
              className={styles.readMore}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Read less" : "Read more"}
            </span>
          )}
        </div>
      </div>
      <p className={styles.disclaimer}>
        *Disclaimer: In order to achieve the best possible results, it's crucial
        to consistently exercise and follow a balanced diet. We encourage you to
        consult with a healthcare professional before starting any exercise
        program.
      </p>
    </div>
  );
};
