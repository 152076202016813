import styles from "./styles.module.scss";

export const Input = ({
  placeholder,
  endAdornment,
  value,
  onChange,
  maxLength,
  type,
  inputmode,
  max,
  min,
}) => {
  return (
    <div className={styles.input}>
      <input
        type={type}
        max={max}
        min={min}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        inputMode={inputmode}
      />
      {endAdornment}
    </div>
  );
};
