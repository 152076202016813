import { axiosInstance } from "..";

export const UserTrainingService = {
  update: (id, progress) => {
    return axiosInstance.put("/me/trainings/" + id , { progress });
  },

  get: () => {
    return axiosInstance.get("/me/trainings");
  },
};
