import { TermsWrapper } from "./TermsWrapper";
import classes from "./styles.module.scss";

export const TermsOfUse = () => {
  return (
    <TermsWrapper
      title="Terms of Use"
      lastUpdated={new Date("2024-09-23").toLocaleDateString()}
    >
      <div className={classes.item}>
        <p>
          These Terms of Use (the <strong>“Terms”</strong>) apply and govern
          your use of EasyFit. Please read them carefully. By proceeding through
          any screen/feature/button which directly refers to these Terms and to
          the respective consent resulting from proceeding so, you confirm that
          you agree to be bound by these Terms.
        </p>
        <p>
          <strong>
            If you do not agree to these Terms or any part thereof, you shall
            not use EasyFit.
          </strong>
        </p>
        <p>
          YOUR SUBSCRIPTION TO EASYFIT WILL AUTOMATICALLY RENEW UNTIL CANCELED
          AS FURTHER DESCRIBED IN SECTIONS 5.2(b) OR 5.3(b) (FOR WEB PURCHASES
          OR IN-APP PURCHASES, RESPECTIVELY). <br />
          <br />
          IF YOU HAVE ANY QUESTIONS OR COMPLAINTS REGARDING EASYFIT, PLEASE
          COMMUNICATE THEM TO THE APPROPRIATE CUSTOMER SUPPORT AGENTS. WE
          ENCOURAGE YOU TO COMMUNICATE WITH US VIA EMAIL, IN WHICH CASE WE CAN
          EFFECTIVELY PROCESS YOUR REQUEST AND RESOLVE THE ISSUE RAPIDLY.
        </p>
      </div>
      <ol>
        <li className={classes.title}> 1. Our Product</li>
        <ol>
          <li>
            <strong>1.1. </strong> EasyFit is a product that offers full-body
            exercise practices and workouts as well as personalized plans on
            specific needs including areas of the body to give an all-inclusive,
            anytime/anywhere solution for everyone from beginners to more
            advanced users, available on the Website (the{" "}
            <strong>“Website”</strong>) and the related mobile application
            distributed via online application stores (the{" "}
            <strong>“App”</strong>), collectively referred to as the{" "}
            <strong>“Product”</strong>. In these Terms, online application
            stores (the <strong>“App Store”</strong>) mean the Apple App Store
            and/or Google Play Market.
          </li>
          <li>
            <strong>1.2. </strong> The Website and the App are made available to
            you by Straiton Limited, with a registered address: Office 11, 1st
            floor, Alpha Tower Pavlou, Nirvana - Aipeias, 3021 Limassol, Cyprus
            (the
            <strong>“Company”</strong>, along with{" "}
            <strong>“we”, “us”, “our”</strong>, and <strong>“ourselves”</strong>
            ). For consistency, <strong>“user”, “consumer”</strong>, along with{" "}
            <strong>"you"</strong> and <strong>“your”</strong>, refers to any
            person who agrees to be bound by these Terms, as described herein.
          </li>
          <li>
            <strong>1.3. </strong> The Product provides you with content in the
            health and fitness field, including software, designs, graphics,
            photos, images, illustrations, animations, videos, scripts, texts,
            music, sounds, voiceover, interactive features, wellness plans, and
            all other materials and content accessible within the Product (the{" "}
            <strong>“Product Content”</strong>). When we refer to the Product in
            these Terms, we are referring to the Product Content as well.
          </li>
          <li>
            <strong>1.4.</strong> Your use of the Product is limited to your
            personal, non-commercial use only. We grant you a personal,
            revocable, non-transferable, non-sublicensable, and non-exclusive
            right and license to access and use the Product, provided that you
            do not (and do not allow any third party to) copy, modify, create a
            derivative work from, reverse engineer, reverse assemble or
            otherwise attempt to discover any source code, sell, assign,
            sublicense, grant a security interest in, or otherwise transfer any
            right in the Product, except where permitted by applicable law. You
            agree not to access the Product by any means other than provided by
            the Company. Your breach of these restrictions shall mean your
            violation of our rights as a licensor. At that, you may be subject
            to prosecution and damages.
          </li>
          <li>
            <strong>1.5.</strong> To get access to the Product you need to buy a
            subscription (either directly on the Website or via the App Store).{" "}
            <strong>
              The subscription is billable and renews automatically at the end
              of the chosen period (e.g. 1 week, 1 month, 3 months, 6 months, 1
              year) until you cancel. You may be offered free trial access to
              the Product for a certain period of time, and once it lapses, your
              access to the Product will automatically continue and you will be
              billed the applicable fees for the paid subscription. To avoid
              being charged for auto-renewal (auto-continuance), you must
              affirmatively cancel the free trial or paid subscription at least
              24 hours before it ends. KINDLY NOTE THAT DELETING THE APP DOES
              NOT CANCEL YOUR SUBSCRIPTION OR FREE TRIAL (IF APPLICABLE)
            </strong>
            . Within the Product, you may also be offered add-on items for an
            additional fee, either one-off or recurrent (e.g. fitness guides,
            VIP customer support service, additional subscriptions). In case you
            purchased an additional subscription, the cancellation of the
            original subscription will not affect it. For the rest of the add-on
            items with recurrent billing, the cancellation of the original
            subscription leads to the cancellation of recurring payments for
            add-on items as well (except for additional subscriptions, if
            applicable). The cancellation of only the recurrent payments for
            add-on items will not affect the original subscription.
          </li>
          <li>
            <strong>1.6.</strong> To create your personalized plan, we may ask
            you to supply certain relevant information, e.g. your current and
            desired physical characteristics, physical performance level,
            lifestyle, eating habits, goals, and target zones. This quiz is
            referred to as the <strong>"onboarding process"</strong> in the
            Terms.
          </li>
          <li>
            <strong>1.7.</strong> In order to use the Product, you need to have
            a smartphone with an operating system of the following or later:
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>🍏 IOS</th>
                  <th>🤖 ANDROID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>16.0</td>
                  <td>8.0</td>
                </tr>
              </tbody>
            </table>{" "}
            Also please make sure you are using the latest version of your web
            browser when accessing the Website, as that will help prevent
            security problems and ensure all the Website features work for you.
          </li>
          <li>
            <strong>1.8.</strong> We may enable access to the App on certain
            smartwatches and/or tablets. If this happens, you acknowledge that
            your ability to use certain features of the App may require you to
            purchase a smartwatch and/or tablet, and that your use of the App on
            a smartwatch and/or tablet will only be possible after subscribing
            to the App on your smartphone. Your access to the App on
            smartwatches and/or tablets shall be regulated by these Terms.
          </li>
        </ol>
        <li className={classes.title}>2. Our Contract With You</li>
        <ol>
          <li>
            <strong>2.1.</strong> In order to use the Product, you must conclude
            a contract with us. You acknowledge and understand that when you
            indicate your consent to these Terms and other policies that may
            apply to you by proceeding through any screen/feature/button which
            directly refers to these Terms and to the respective consent
            resulting from proceeding so, you are submitting a legally binding
            electronic signature and are concluding a contract with us, and
            thus, you are agreeing to be bound by these Terms, which constitute
            the contract between you and us in respect to your use of the
            Product.{" "}
          </li>
          <li>
            <strong>2.2. </strong> If you accessed the Product directly on the
            Website or via AppStore, your contract is concluded with STRAITON
            LIMITED.
          </li>
          <li>
            <strong>2.3.</strong> We also understand that you are agreeing to
            cooperate with us under these Terms when you start the onboarding
            process within the Product and/or make a purchase from us (either on
            the Website or via the App Store), and/or create your user account
            with us, and/or use the Product, and/or download any materials from
            the Product.
          </li>
          <li>
            <strong>
              <strong>2.4.</strong>
              The Product is not intended for individuals under the age of 16.
              You hereby represent and warrant to the Company that you meet the
              foregoing qualification. Otherwise, please do not use the Product.
            </strong>
          </li>
          <li>
            <strong>2.5.</strong> No one other than a party to these Terms
            (namely, you or us) has any right to enforce any provision thereof.
          </li>
        </ol>
        <li className={classes.title}>
          3. Other Policies That May Apply to You
        </li>
        <ol>
          <li>
            <strong>3.1.</strong> Along with these Terms, you also acknowledge
            the applicability of and agree to:
            <p className={classes.marginLeft}>
              Our Privacy Policy, which sets out information, among other
              things, about:
            </p>
            <ul>
              <li>how and why we process your personal data;</li>
              <li>
                your rights in relation to your personal data and how to
                exercise them;
              </li>
              <li>cookies and similar technologies used by our Website..</li>
            </ul>
            <p className={classes.marginLeft}>
              Our Subscription Terms, which provide details about:
            </p>
            <ul>
              <li>our subscription model;</li>
              <li>subscription payment methods;</li>
              <li>possible means of subscription cancellation.</li>
            </ul>
            <p className={classes.marginLeft}>
              {" "}
              Our Refund Policy, which explains:
            </p>
            <ul>
              <li>general refund rules;</li>
              <li>when you are eligible to receive a refund;</li>
              <li> how you can get your money back.</li>
            </ul>
          </li>
          <li>
            <strong>3.2.</strong> Our Subscription Terms apply to you if you
            purchased the Product via the App Store. Subscriptions purchased
            directly on the Website are solely regulated by these Terms.
          </li>
          <li>
            <strong>3.3.</strong> Our Refund Policy applies to you if you
            purchased the Product directly on the Website. Purchases made via
            the App Store are subject to such store's refund policies:{" "}
            <a
              rel="noreferrer"
              href="https://support.apple.com/en-gb/118223"
              target="_blank"
            >
              Request a refund on Apple App Store
            </a>
            ,{" "}
            <a
              rel="noreferrer"
              href="https://support.google.com/googleplay/workflow/9813244?visit_id=637981511087133604-384729094&p=refundAWF&rd=1"
              target="_blank"
            >
              Request a refund on Google Play Market.
            </a>
          </li>
        </ol>
        <li className={classes.title}>4. Important Disclaimers</li>
        <ol>
          <li>
            <strong>4.1.</strong> YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE
            HAVE SET OUR PRICES AND ENTERED INTO THESE TERMS WITH YOU, IN
            PARTICULAR, IN RELIANCE UPON THE IMPORTANT DISCLAIMERS, LIMITATION
            OF LIABILITY, AND INDEMNITY SECTIONS SET FORTH IN THESE TERMS, WHICH
            ALLOCATE THE RISKS BETWEEN US AND FORM THE BASIS OF A BARGAIN
            BETWEEN US. ABSENT SUCH PROVISIONS, THESE TERMS AND YOUR ACCESS TO
            THE PRODUCT WOULD BE MATERIALLY DIFFERENT (OR WE MAY BE UNABLE OR
            UNWILLING TO PROVIDE SUCH ACCESS AT ALL).
          </li>
          <li>
            <strong> 4.2. WE DO NOT PROVIDE ANY MEDICAL ADVICE</strong>{" "}
            <p>
              THE PRODUCT IS PROVIDED FOR GENERAL INFORMATION AND ENTERTAINMENT
              PURPOSES ONLY. YOU EXPRESSLY AGREE THAT THE COMPANY IS NOT
              PROVIDING MEDICAL ADVICE VIA THE PRODUCT. The Product Content is
              not intended to be and should not be used in place of: (a) the
              advice of your physician or other medical professional; (b) a
              visit, call, or consultation with your physician or other medical
              professional; and/or (c) information contained on or in any
              product packaging or label. Should you have any health-related
              questions, please call or see your physician or other medical
              professional promptly. Should you have an emergency, please call
              the emergency service (e.g. 911 for the United States)
              immediately. You should never disregard medical advice or delay in
              seeking medical advice because of any Product Content, nor should
              you use any Product Content for diagnosing or treating a health
              problem. The transmission and receipt of the Product Content, in
              whole or in part, or communication via email or other means, does
              not constitute or create a doctor-patient, therapist-patient, or
              other healthcare professional relationship between you and us.
            </p>
          </li>
          <li>
            <strong> 4.3. LISTEN TO WHAT YOUR BODY IS TELLING YOU</strong>{" "}
            <p>
              {" "}
              YOU EXPRESSLY AGREE THAT A CONDITION FOR THE USE OF THE PRODUCT IS
              THAT YOU MUST BE IN A GOOD GENERAL STATE OF HEALTH. IF YOU HAVE
              KNOWLEDGE OF ANY PRE-EXISTING MEDICAL CONDITIONS YOU MUST SEEK
              MEDICAL ADVICE FROM YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONAL
              BEFORE YOU START USING THE PRODUCT. The following general rule
              applies: listen to what your body is telling you. Before using the
              Product for the first time and/or while using the Product, if you
              have any doubts about your health (e.g. because you are
              experiencing considerable pain, a general malaise, shortness of
              breath, nausea, or dizziness), consult your physician or other
              medical professional before starting and/or continuing using the
              Product. You are solely responsible for determining, in
              conjunction with medical professional(s) if necessary, whether or
              not you should use the Product based on your current health.
            </p>
          </li>
          <li>
            {" "}
            <strong>
              4.4. READ INFORMATION ABOUT FOOD PRODUCTS CAREFULLY
            </strong>{" "}
            <p>
              {" "}
              YOU EXPRESSLY AGREE THAT IN CASES EASYFIT RELATES TO NUTRITION, WE
              DO NOT ASSUME ANY LIABILITY FOR INACCURACIES OR MISSTATEMENTS
              ABOUT ANY NUTRITIONAL INFORMATION IN THE FOOD DATABASES, FOOD
              PRODUCTS, FOOD RECIPES, OR MEAL PLANS. Before using or consuming a
              product you should carefully read all information provided by the
              manufacturers of the food product, whether online or on the actual
              product packaging and labels, including nutrient content,
              ingredients, food allergens, contact information, and health
              claims. For additional information about a food product, please
              contact the manufacturer directly. You are responsible for
              verifying that the foods and nutrients recommended as part of the
              meal plan or fitness guide do not contain any ingredients or
              contents to which you are allergic or which may cause food
              intolerance.
            </p>
          </li>
          <li>
            <strong>4.5. RESULTS WILL DIFFER FOR EACH INDIVIDUAL</strong>{" "}
            <p>
              {" "}
              YOU EXPRESSLY AGREE THAT WE MAKE NO GUARANTEES CONCERNING THE
              SPECIFIC LEVEL OF SUCCESS YOU MAY ACHIEVE AND YOU ACCEPT THE RISK
              THAT RESULTS WILL DIFFER FOR EACH INDIVIDUAL SINCE EACH INDIVIDUAL
              HAS: (a) A UNIQUE HEALTH, PHYSICAL DISPOSITION, AND GENETIC
              PROFILE; (b) A UNIQUE BACKGROUND, LIFE EXPERIENCE, DEDICATION,
              DESIRE, LEVEL OF COMMITMENT, AND MOTIVATION; AND/OR (c) A UNIQUE
              STARTING POINT AND PRECONDITIONS. MOREOVER, WE MAKE NO GUARANTEES
              THAT: (a) YOU WILL ACHIEVE THE SAME OR SIMILAR RESULTS AS MAY BE
              PROVIDED IN THE TESTIMONIALS AND/OR OTHER ILLUSTRATIVE EXAMPLES ON
              THE PRODUCT SINCE THESE CONCERN THE SPECIFIC EXPERIENCE OF THE
              PERSON/GROUP REFERRED TO IN SUCH TESTIMONIALS/EXAMPLES, AND THUS,
              MAY DIFFER FOR ANY OTHER PERSON; (b) EXAMPLES OF YOUR PAST FITNESS
              RESULTS CAN BE REPEATED IN THE FUTURE; (c) YOU WILL ACHIEVE
              VISIBLE RESULTS IF YOU DO NOT FOLLOW OUR RECOMMENDATIONS; AND/OR
              (d) YOU WILL MAINTAIN THE RESULTS YOU EXPERIENCE IF YOU DO NOT
              CONTINUE FOLLOWING OUR PROGRAMS. WE CANNOT GUARANTEE AND DO NOT
              PROMISE ANY SPECIFIC RESULTS FROM THE USE OF THE PRODUCT.
            </p>
          </li>
          <li>
            <strong>
              4.6. WE MAKE NO GUARANTEES IF YOU GIVE US INACCURATE DATA
            </strong>{" "}
            <p>
              IN ORDER TO CREATE A PLAN FOR YOU, WE ASK YOU TO COMPLETE THE
              ONBOARDING PROCESS. FOR EXAMPLE, WE ASK YOU TO INFORM US OF YOUR
              CURRENT AND DESIRED PHYSICAL CHARACTERISTIC, PHYSICAL PERFORMANCE
              LEVEL, LIFESTYLE, EATING HABITS, GOALS, TARGET ZONES, ETC. YOU ARE
              RESPONSIBLE FOR ENSURING THAT ALL INFORMATION PROVIDED BY YOU
              DURING THE ONBOARDING PROCESS IS TRUE AND ACCURATE. IN THE EVENT
              YOU PROVIDE US WITH INACCURATE OR INCOMPLETE DATA DURING THE
              ONBOARDING PROCESS, WE ARE NOT RESPONSIBLE FOR ANY OUTCOMES, IN
              PARTICULAR, THE SUITABILITY OF THE PLAN CREATED FOR YOU.
            </p>
          </li>
          <li>
            {" "}
            <strong>
              4.7. USE AT YOUR SOLE RISK, ON AN "AS IS" BASIS{" "}
            </strong>{" "}
            <p>
              EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY APPLICABLE
              LAW, YOU EXPRESSLY AGREE THAT YOUR USE OF THE PRODUCT IS AT YOUR
              SOLE RISK, AND THE PRODUCT IS PROVIDED ON AN "AS IS" BASIS. THE
              COMPANY AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
              CONTRACTORS, PARTNERS, AND LICENSORS (THE{" "}
              <strong>“RELEASED PARTIES”</strong>) EXPRESSLY DISCLAIM ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. In particular,
              the Released Parties make no, and expressly disclaim any, warranty
              that: (a) the Product will meet your requirements; (b) any Product
              Content or other information or other material purchased or
              obtained by you through the Product will meet your expectations or
              be complete or accurate; (c) the Product will be uninterrupted,
              timely, secure, or error-free, and/or (d) any errors in the
              Product will be corrected. We disclaim liability for any errors or
              omissions, or for unintended technical inaccuracies, including
              interruption of the Product for any technical reason, or
              typographical errors in any Product Content, as well as violation
              of any ethical or moral standards applicable in your community to
              sexual education and related materials.​ Any Product Content
              obtained through the use of the Product is accessed at your own
              discretion and risk, and you will be solely responsible for any
              damage to your computer system or mobile device or loss of data
              that results from the use of any such material. THE PRODUCT MAY
              NOT BE AVAILABLE IN ALL LANGUAGES OR IN ALL COUNTRIES, AND WE MAKE
              NO REPRESENTATION THAT THE FUNCTIONALITY OF THE PRODUCT WILL BE
              APPROPRIATE, ACCURATE, OR AVAILABLE FOR USE IN ANY PARTICULAR
              LOCATION.
            </p>
          </li>
          <li>
            <strong>
              4.8. WE ARE NOT RESPONSIBLE FOR ANY THIRD-PARTY SERVICES
            </strong>{" "}
            <p>
              {" "}
              THE PRODUCT MAY CONTAIN LINKS TO THIRD PARTIES AND/OR THIRD-PARTY
              WEBSITES. WE DISCLAIM ANY RESPONSIBILITY FOR THE PRODUCTS,
              SERVICES, OR CONTENT OFFERED BY ANY THIRD PARTY (REGARDLESS OF
              WHETHER WE DIRECTLY OR INDIRECTLY LINK USERS TO SUCH THIRD PARTIES
              AND/OR THEIR WEBSITES OR RECEIVE ANY COMPENSATION FOR SUCH LINKING
              OR PROMOTION). You expressly agree that you need to take
              appropriate steps to determine whether accessing the third-party
              websites, acquiring or using the third-party products or services,
              and relying on the third-party content is appropriate and safe,
              including protecting your personal information and privacy. To be
              aware of the terms under which the third-party products, services,
              or content are provided to you, please refer to the relevant
              policies and/or user agreements of those third parties. We do not
              guarantee that any third-party product will function with the
              Product or will be error-free.
            </p>
          </li>
          <li>
            <strong>
              4.9. REFERENCE TO OTHER PRODUCTS OR EXPERTS IS NOT AN ENDORSEMENT
            </strong>
            <p>
              FROM TIME TO TIME, WE MAY REFER TO OTHER PRODUCTS OR EXPERTS. ANY
              SUCH REFERENCE IS NOT INTENDED TO BE AN ENDORSEMENT OR STATEMENT
              THAT THE INFORMATION PROVIDED BY THE OTHER PARTY IS ACCURATE. THE
              COMPANY PROVIDES THIS INFORMATION AS A REFERENCE FOR USERS. IT IS
              YOUR RESPONSIBILITY TO CONDUCT YOUR OWN INVESTIGATION AND MAKE
              YOUR OWN DETERMINATION ABOUT ANY SUCH PRODUCT OR EXPERT.
            </p>
          </li>
          <li>
            <strong>4.10. RESERVATION OF RIGHTS </strong>

            <p>
              THE PRODUCT ITSELF, AS WELL AS ALL CONTENT THEREIN, INCLUDING
              SOFTWARE, DESIGNS, GRAPHICS, PHOTOS, VIDEOS, SCRIPTS, TEXTS,
              MUSIC, SOUNDS, AND VOICEOVER, IS OUR INTELLECTUAL PROPERTY,
              PROTECTED BY COPYRIGHT, TRADEMARK, AND OTHER INTELLECTUAL PROPERTY
              LAWS GLOBALLY. ALL TRADEMARKS, SERVICE MARKS, GRAPHICS, AND LOGOS
              USED IN CONNECTION WITH THE PRODUCT ARE TRADEMARKS OR REGISTERED
              TRADEMARKS. YOU EXPRESSLY UNDERSTAND AND AGREE THAT THESE TERMS DO
              NOT GRANT YOU ANY RIGHT, TITLE, OR INTEREST IN THE PRODUCT,
              CONTENT WITHIN THE PRODUCT, OR IN ANY TRADEMARKS, WHETHER
              REGISTERED OR NOT. ALL INTELLECTUAL PROPERTY RIGHTS, TITLE, AND
              INTEREST IN RELATION TO THE PRODUCT WILL (AS BETWEEN THE PARTIES)
              REMAIN WITH US.
            </p>
          </li>
          <li>
            <strong>4.11. ENGLISH TEXT OF THE TERMS PREVAILS </strong>

            <p>
              THESE TERMS WERE ORIGINALLY WRITTEN IN THE ENGLISH LANGUAGE. IF AN
              INTERPRETATION OF A TRANSLATED VERSION OF THE TERMS CONFLICTS WITH
              THE ENGLISH VERSION MEANING, THE ENGLISH VERSION SHALL PREVAIL.
            </p>
          </li>
          <li>
            <strong>
              4.12. THIS SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY
              APPLICABLE LAW{" "}
            </strong>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES OR LIMITATIONS OF APPLICABLE STATUTORY RIGHTS OF THE
              CONSUMER, SO THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY
              NOT APPLY TO YOU.
            </p>
          </li>
          <li>
            <strong>
              4.13. WE ARE NOT RESPONSIBLE FOR THE CONTENT YOU POST
            </strong>{" "}
            <p>
              {" "}
              ALL THE INFORMATION, DATA, TEXT, SOFTWARE, SOUND, PHOTOGRAPHS,
              GRAPHICS, VIDEO, MESSAGES, POSTS, TAGS, OR OTHER MATERIALS YOU
              CREATE AND SHARE IN CONNECTION WITH THE PRODUCT (
              <strong>“USER CONTENT”</strong>) IS OWNED BY YOU. YOU ACKNOWLEDGE
              THAT YOU BEAR FULL RESPONSIBILITY FOR ALL THE USER CONTENT YOU
              UPLOAD, POST, EMAIL, TRANSMIT, OR OTHERWISE SHARE THROUGH THE
              PRODUCT, AND NOT THE COMPANY. THE COMPANY DOES NOT ACTIVELY REVIEW
              OR MONITOR THE USER CONTENT POSTED BY ITS USERS, AND THEREFORE,
              CANNOT GUARANTEE THE ACCURACY, INTEGRITY, OR QUALITY OF SUCH USER
              CONTENT. BY USING THE PRODUCT, YOU AGREE THAT THE COMPANY IS NOT
              ACCOUNTABLE FOR AND DOES NOT ENDORSE THE USER CONTENT YOU UPLOAD.
              YOUR USER CONTENT WILL NOT BE TREATED AS CONFIDENTIAL BY THE
              COMPANY, EXCEPT AS OUTLINED IN OUR PRIVACY NOTICE. WE WILL NOT BE
              HELD LIABLE FOR ANY USE OR DISCLOSURE OF THE USER CONTENT YOU
              PROVIDE.
            </p>
          </li>
        </ol>
        <li className={classes.title}>5. Fees and Payments</li>
        <ol>
          <li>
            <strong>5.1. </strong>
            We offer subscriptions to the Product Content which may be purchased
            via the following means: (a) directly on the Website (the{" "}
            <strong>“Web Purchase”</strong>) or (b) via the App Store (the{" "}
            <strong>“In-App Purchase”</strong>). All applicable fees will be
            provided to you on the payment screen and at checkout before you
            authorize any payment within the Product. Some limited Product
            Content may be made available to you free of charge.{" "}
          </li>
          <li>
            <strong>5.2. WEB PURCHASE</strong>{" "}
            <h4>a. Web Purchase procedure</h4>{" "}
            <p>
              {" "}
              Once you have completed the onboarding process on the Website, you
              will be given the option to purchase a subscription to the Product
              Content with the subscription fees, subscription terms, and
              periodicity (e.g. 1 week, 1 month, 3 months, 6 months, 1 year)
              indicated, as well as the available payment methods (e.g.
              Mastercard or Visa Bank Card, PayPal, Apple Pay, Google Pay). In
              order to make a Web Purchase, you need to choose a respective
              purchase option and authorize the corresponding payment on the
              payment screen. Please note that to process the payment we use
              third-party services (payment processors), whom you authorize to
              charge you the applicable fees using the payment method you have
              chosen. Such services enable the transaction initiated by you and
              notify us of its completion. Your credit or debit card information
              will be encrypted at the point of transaction. We will send you
              direct instructions on how to access the purchased Product Content
              promptly after your transaction is successfully validated by our
              servers. <p />
              <h4>b. Auto-Renewal</h4>{" "}
              <p>
                <strong>
                  {" "}
                  By proceeding with a Web Purchase, you understand and agree
                  that your subscription will automatically renew unless you
                  cancel as described herein and that you are going to be
                  charged periodically upon renewal depending on the term and
                  periodicity of the subscription you purchased. Your
                  subscription will be automatically renewed at the end of the
                  chosen subscription term unless you cancel it at least 24
                  hours before the end of the chosen subscription term. PLEASE
                  REMEMBER THAT DELETING THE APP DOES NOT CANCEL YOUR
                  SUBSCRIPTION. The period of auto-renewal will be the same as
                  your initial subscription period unless otherwise disclosed to
                  you within the Product. The renewal rate will be no more than
                  the rate for the immediately prior subscription period,
                  excluding any promotional or discount pricing, unless we
                  notify you of a rate change prior to your auto-renewal.
                </strong>{" "}
              </p>
              <h4> c. Add-On Items</h4>{" "}
              <p>
                In addition to your purchased subscription, the Website may
                offer you add-on items (e.g. fitness guides, VIP customer
                support service, additional subscription) for an additional fee,
                either one-off or recurrent. This purchase is optional: your
                subscription is not conditional on such a purchase. In case you
                purchased an additional subscription, the cancellation of the
                original subscription will not affect it. For the rest of the
                add-on items with recurrent billing, the cancellation of the
                original subscription leads to the cancellation of recurring
                payments for add-on items as well (except for additional
                subscriptions, if applicable). The cancellation of only the
                recurrent payments for add-on items will not affect the original
                subscription.
              </p>
              <h4>d. Refunds</h4>{" "}
              <p>
                {" "}
                Refunds of Web Purchases are regulated by our Refund Policy. As
                a general rule, Web Purchases are final and cannot be canceled,
                and we will not refund any transaction once it has been made.
                However, the Company will provide refunds in cases and to the
                extent required by mandatory provisions of applicable law.
              </p>
            </p>
          </li>
          <li>
            {" "}
            <strong>5.3. IN-APP PURCHASE</strong>{" "}
            <h4>a. In-App Purchase procedure</h4> Once you have completed the
            onboarding process within the App, you will be given the option to
            purchase a subscription to the Product Content with the subscription
            fees, subscription terms, and periodicity (e.g. 1 week, 1 month, 3
            months, 6 months, 1 year) indicated. In order to make an In-App
            Purchase, you need to choose a respective purchase option and
            authorize the corresponding payment on the payment screen. Your
            payment will be processed by the App Store, and you authorize the
            App Store to charge you the applicable fees using the payment method
            that you submitted to it. You will be granted access to the Product
            Content promptly after your transaction’s success is properly
            validated by the App Store. <h4> b. Auto-Renewal</h4>{" "}
            <strong>
              {" "}
              By proceeding with an In-App Purchase, you understand and agree
              that your subscription will automatically renew unless you cancel
              as described herein and that you are going to be charged
              periodically upon renewal depending on the term and periodicity of
              the subscription you purchased. Your subscription will be
              automatically renewed at the end of the chosen subscription term
              unless you cancel it at least 24 hours before the end of the
              chosen subscription term. PLEASE REMEMBER THAT DELETING THE APP
              DOES NOT CANCEL YOUR SUBSCRIPTION. The period of auto-renewal will
              be the same as your initial subscription period unless otherwise
              disclosed to you within the Product. The renewal rate will be no
              more than the rate for the immediately prior subscription period,
              excluding any promotional or discount pricing, unless we notify
              you of a rate change prior to your auto-renewal.
            </strong>{" "}
            <h4> c. Add-On Items</h4> In addition to your purchased
            subscription, the Website may offer you add-on items (e.g. fitness
            guides, VIP customer support service, additional subscription) for
            an additional fee, either one-off or recurrent. This purchase is
            optional: your subscription is not conditional on such a purchase.
            In case you purchased an additional subscription, the cancellation
            of the original subscription will not affect it. For the rest of the
            add-on items with recurrent billing, the cancellation of the
            original subscription leads to the cancellation of recurring
            payments for add-on items as well (except for additional
            subscriptions, if applicable). The cancellation of only the
            recurrent payments for add-on items will not affect the original
            subscription. <h4>d. Refunds </h4>You agree that we cannot refund
            you any In-App Purchase, since these transactions are processed by
            the respective App Store. However, you may ask for a refund
            according to the App Store’s refund policies:{" "}
            <a
              rel="noreferrer"
              href="https://support.apple.com/en-gb/118223"
              target="_blank"
            >
              Request a refund on Apple App Store
            </a>
            ,{" "}
            <a
              rel="noreferrer"
              href="https://support.google.com/googleplay/workflow/9813244?visit_id=637981511087133604-384729094&p=refundAWF&rd=1"
              target="_blank"
            >
              Request a refund on Google Play Market.
            </a>
          </li>
          <li>
            <strong>5.4. FREE TRIALS</strong>{" "}
            <p>
              We may offer you free trial access to the Product Content, but we
              are not obliged to do so. Such access will be given for a certain
              period of time and according to the details specified when you
              sign up for the offer.{" "}
              <strong>
                Unless you cancel the free trial at least 24 hours before it
                ends, your access to the Product will automatically convert into
                a paid subscription on the basis described when you enrolled in
                the trial and you will be charged the applicable fees for the
                Product upon such conversion as well as periodically thereafter
                upon renewal depending on the term and periodicity of the
                subscription you purchased. PLEASE REMEMBER THAT DELETING THE
                APP DOES NOT CANCEL YOUR FREE TRIAL.
              </strong>{" "}
              We may send you a reminder when your free trial is about to end,
              but we do not guarantee any such notifications. It is ultimately
              your responsibility to know when the free trial will end. We
              reserve the right, in our absolute discretion, to modify or
              terminate any free trial offer and your access to the Product
              during the free trial without notice and with no liability. We
              reserve the right to limit your ability to take advantage of
              multiple free trials.
            </p>
          </li>
          <li>
            <strong>5.5. FEE CHANGES</strong>{" "}
            <p>
              {" "}
              To the extent permitted by applicable law, we may change
              subscription fees at any time (such changes will become effective
              upon your next renewal). We will give you reasonable notice of any
              such pricing changes by posting the new prices on or through the
              App and/or by sending you an email notification. If you do not
              agree to the price change, you must cancel the applicable
              subscription prior to the change going into effect.
            </p>
          </li>
          <li>
            <strong>5.6. </strong>
            If you do not pay the fees or charges due, we may make reasonable
            efforts to notify you and resolve the issue; however, we reserve the
            right to terminate these Terms and your access to the Product (and
            we may do so without notice).
          </li>
        </ol>
        <li className={classes.title}>6. Registration</li>
        <ol>
          <li>
            <strong>6.1. </strong>
            In order to use the Product, you may need to register a user
            account. Please follow the on-screen instructions if that is the
            case.{" "}
          </li>
          <li>
            <strong>6.2. </strong>
            During registration, you agree to provide true and accurate
            information and not to misrepresent your identity by registering an
            account in the name of another person. You should keep your user
            account information accurate and up-to-date (particularly your email
            address - if you ever forget your password, a working email address
            is often the only way for us to verify your identity and help you
            log back in).
          </li>
          <li>
            <strong>6.3. </strong>
            We may also allow you to access the Product Content without
            registration. Please bear in mind that in this case your data may
            not be backed up and you may lose it if you delete the App, lose
            your smartphone, etc.
          </li>
          <li>
            <strong>6.4. </strong>
            You acknowledge and understand that you are solely responsible for
            maintaining the confidentiality of your user account information, as
            well as for all activities that occur under your user account. We
            endeavor to use reasonable security measures to protect against
            unauthorized access to your user account. We cannot, however,
            guarantee the absolute security of your user account and we cannot
            promise that our security measures will prevent third-party
            “hackers” from illegally accessing the Product. You agree to
            immediately notify the Company of any unauthorized use of your user
            account or any other breach of security.
          </li>
        </ol>
        <li className={classes.title}>7. Unauthorized Use</li>
        <ol>
          <li>
            {" "}
            <strong>7.1. </strong>
            As a user of the Product, you agree not to use the Product:
            <ol className={classes.numberList}>
              <li>
                {" "}
                for any commercial purpose or revenue-generating endeavor;
              </li>
              <li>
                for creating any other product, service, or software (including
                those which are competitive with the Product);
              </li>
              <li>
                {" "}
                in any other way that violates these Terms and other policies
                that may apply to you;
              </li>
              <li> and/or in any fraudulent, criminal, or unlawful manner.</li>
            </ol>
          </li>
          <li>
            <strong>7.2. </strong>
            As a user of the Product, you also agree not to:
            <ol className={classes.numberList}>
              <li>
                {" "}
                make any modification, adaptation, improvement, enhancement,
                translation, or derivative work of the Product;
              </li>
              <li>
                {" "}
                decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the
                Product;
              </li>
              <li>
                circumvent, disable, or otherwise interfere with
                security-related features of the Product;
              </li>
              <li>
                {" "}
                attempt to bypass any measures of the Product designed to
                prevent or restrict access to the Product, or any portion of the
                Product;
              </li>
              <li>
                use, launch, develop, or distribute any automated system,
                including without limitation, any spider, robot, cheat utility,
                scraper, or offline reader that accesses the Product, or use or
                launch any unauthorized script or other software;
              </li>
              <li>
                {" "}
                send to us or distribute in any way within the Product files
                that contain viruses, worms, trojans, corrupted files, or any
                other similar software or programs that may damage the operation
                of another's computer;
              </li>
              <li>
                {" "}
                make the Product available over a network or other environmental
                permitting access or use by multiple devices or users at the
                same time;
              </li>
              <li>
                {" "}
                make any links to the Product without our written permission
                (except if you do so in a way that is fair and legal and does
                not damage our reputation or take advantage of it);
              </li>
              <li>
                {" "}
                take any action that may interfere with, disrupt, or create an
                undue burden on the Product;
              </li>
              <li>
                {" "}
                and/or take any action that may disparage, tarnish, or otherwise
                harm us and/or the Product.
              </li>
            </ol>
          </li>
          <li>
            <strong>7.3. </strong>
            Many countries periodically impose restrictions on dealings with
            certain territories, regimes, or persons that pose a threat to
            international peace and security or are otherwise targeted. By using
            the Product, you confirm that you are neither a resident of, nor
            located in, any territory currently embargoed by the United Nations,
            the European Union, the United Kingdom, or the United States, and
            that you are not otherwise restricted from using the Product. You
            also agree that you will not use the Product for any purposes
            prohibited by applicable law. In particular, but without limitation,
            you may not export, or permit the export of, the Product to any of
            the embargoed territories, or to anyone otherwise restricted from
            using the Product. You understand and agree that we may modify,
            limit, or disable your access to the Product at any time to comply
            with applicable law. In particular, but without limitation, you
            agree that the Product available to you in your home country may not
            be available to you when traveling outside of your home country, and
            the Product may also cease to be available in your home country, as
            a result of the above.
          </li>
          <li>
            <strong>
              7.4. In other words, you must comply with the law and these Terms,
              and you promise not to take any action that is prohibited or
              harmful, including violating or attempting to violate the security
              of the Product and/or any applicable sanction regulations.
            </strong>
          </li>
        </ol>
        <li className={classes.title}>8. Limitation of Liability</li>
        <ol>
          <li>
            <strong>8.1. </strong>
            WE ARE BUILDING THE BEST PRODUCT WE CAN FOR YOU BUT WE CANNOT
            PROMISE IT WILL BE PERFECT. WE ARE NOT LIABLE FOR VARIOUS THINGS
            THAT COULD GO WRONG AS A RESULT OF YOUR USE OF THE PRODUCT OR THAT
            ARE BEYOND OUR CONTROL.
          </li>
          <li>
            <strong>8.2. </strong>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO
            CIRCUMSTANCES SHALL WE, OUR SUBSIDIARIES, AFFILIATES, RELATED
            PARTIES, SUCCESSORS, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES,
            CONTRACTORS, PARTNERS, OR LICENSORS (THE{" "}
            <strong>“RELEASED PARTIES”</strong>) BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR: (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR
            INDIRECTLY); (II) ANY LOSS OF GOODWILL; (III) ANY LOSS OF
            OPPORTUNITY; (IV) ANY LOSS OF DATA SUFFERED BY YOU; (V) ANY
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
            DAMAGES (EVEN IF THE RELEASED PARTIES HAVE BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES); OR (IV) ANY PERSONAL INJURY, INCLUDING
            DEATH, ARISING OUT OF OR RELATING TO:
            <ol className={classes.numberList}>
              <li>YOUR USE OR MISUSE OF THE PRODUCT; </li>
              <li>
                {" "}
                YOUR USE OR MISUSE OF EQUIPMENT OR PROGRAMS CREATED BY THE
                COMPANY (INCLUDING, WITHOUT LIMITATION, ANY TRAINING PLAN) WHILE
                ENGAGED IN FITNESS ACTIVITIES;
              </li>
              <li> ACCURACY OF THE PRODUCT CONTENT;</li>
              <li>
                ANY INFORMATION OR MATERIALS OBTAINED BY YOU THROUGH THE
                PRODUCT;
              </li>
              <li>
                {" "}
                ANY DELAY OR INABILITY TO USE THE PRODUCT EXPERIENCED BY YOU;
              </li>
              <li>
                YOUR DEALINGS WITH ANY THIRD-PARTY SERVICE PROVIDERS (REGARDLESS
                OF WHETHER WE DIRECTLY OR INDIRECTLY LINK YOU TO SUCH
                THIRD-PARTY SERVICE PROVIDERS);
              </li>
              <li>
                {" "}
                YOUR FAILURE TO KEEP YOUR PASSWORD SECURE AND CONFIDENTIAL;
              </li>
              <li>
                {" "}
                AND/OR ANY OTHER BEHAVIOR THAT IS NOT IN LINE WITH AND/OR
                VIOLATES THESE TERMS, IN PARTICULAR, PROVISIONS OF THE IMPORTANT
                DISCLAIMERS, UNAUTHORIZED USE SECTIONS SET FORTH IN THESE TERMS.
              </li>
            </ol>
          </li>
          <li>
            <strong>8.3. </strong>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU AGREE
            THAT THE AGGREGATE LIABILITY OF THE COMPANY PURSUANT TO THESE TERMS
            IS LIMITED TO THE GREATER OF (I) THE AMOUNTS YOU HAVE PAID TO THE
            COMPANY WITHIN THE LAST 12 MONTHS OR (II) $50 USD.
          </li>
          <li>
            <strong>8.4. </strong>
            BY ENTERING INTO THIS RELEASE, YOU ACKNOWLEDGE THAT YOU HAVE READ
            AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE THE BENEFITS OF, SECTION
            1542 OF THE CIVIL CODE OF CALIFORNIA (AND ANY SIMILAR LAW OF ANY
            STATE, COUNTRY, OR TERRITORY), WHICH PROVIDES AS FOLLOWS: “A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING
            PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
            TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER,
            WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR
            OR RELEASED PARTY”.
          </li>
          <li>
            {" "}
            <strong>
              8.5. WE DO NOT EXCLUDE OR LIMIT IN ANY WAY OUR LIABILITY TO YOU
              WHERE IT WOULD BE UNLAWFUL TO DO SO.
            </strong>
            THIS MAY INCLUDE, FOR EXAMPLE, LIABILITY FOR DEATH OR PERSONAL
            INJURY CAUSED BY OUR GROSS NEGLIGENCE OR THAT OF OUR EMPLOYEES,
            AGENTS, OR SUBCONTRACTORS. MOREOVER, WHERE ANY PROVISION OF THESE
            TERMS IS EXPRESSED TO EXCLUDE OR LIMIT LIABILITY TO A GREATER EXTENT
            THAN PERMITTED BY APPLICABLE LAW, THAT PROVISION SHALL BE DEEMED TO
            ONLY EXCLUDE OR LIMIT OUR LIABILITY TO THE MAXIMUM EXTENT PERMITTED
            BY APPLICABLE LAW.
          </li>
          <li>
            <strong>8.6. </strong>
            ANY CLAIM UNDER THESE TERMS MUST BE BROUGHT WITHIN ONE (1) YEAR
            AFTER THE CAUSE OF ACTION ARISES, OR SUCH CLAIM OR CAUSE OF ACTION
            IS FOREVER BARRED.
          </li>
        </ol>
        <li className={classes.title}>9. Indemnity</li>
        <ol>
          <li>
            <strong>9.1. </strong>
            To the extent permitted by applicable law, you will indemnify,
            defend, and hold us, our subsidiaries, affiliates, related parties,
            successors, officers, agents, representatives, employees,
            contractors, partners, and licensors (the{" "}
            <strong>“Indemnified Parties”</strong>) harmless from any claim or
            demand, including reasonable attorneys’ fees, made by any third
            party due to or arising out of:
            <ol className={classes.numberList}>
              <li>your use or misuse of the Product;</li>
              <li>
                {" "}
                your violation of these Terms and/or other policies that may
                apply to you;
              </li>
              <li>your violation of applicable law;</li>

              <li>
                {" "}
                your provision to any of the Indemnified Parties of information
                or other data;
              </li>
              <li>
                {" "}
                any misrepresentation made by you to any of the Indemnified
                Parties;
              </li>
              <li>
                your use of any products, services, or content offered by any
                third-party websites;{" "}
              </li>
              <li> your violation of the rights of any third party;</li>
              <li>
                {" "}
                and/or any disputes or issues between you and any third party.
              </li>
            </ol>
          </li>
          <li>
            <strong>9.2. </strong>
            We reserve the right, at your expense, to assume the exclusive
            defense and control of any matter for which you are required to
            indemnify us under these Terms, and you agree to cooperate with our
            defense of these claims. You agree not to settle any matter without
            our prior written consent. We will use reasonable efforts to notify
            you of any such claim, action, or proceeding upon becoming aware of
            it.
          </li>
        </ol>
        <li className={classes.title}>10. Termination of These Terms</li>
        <ol>
          <li>
            <strong>10.1. </strong>
            These Terms and your access to the Product shall remain in effect
            unless and until terminated by either you or us. Termination of
            these Terms automatically terminates all rights and licenses granted
            to you under these Terms, including all rights to use the Product.{" "}
          </li>
          <li>
            <strong>10.2. YOUR RIGHT TO TERMINATE THESE TERMS</strong>
            <p>
              {" "}
              You have the right to terminate these Terms by canceling your
              subscription or free trial access to the Product (if such a trial
              is offered by the Company). You may do this at any time and at
              your sole discretion. In order to cancel your subscription or free
              trial access to the Product, please follow the below instructions:{" "}
            </p>
            <p className={classes.marginLeft}>
              If your purchase was made (free trial was received) directly on
              the Website:
              <ul>
                <li>
                  {" "}
                  please log into your account → tap “Subscriptions” or “Manage
                  Subscriptions” → tap “Cancel Subscription” or communicate your
                  cancellation request to{" "}
                  <a href="mailto:apps@straitonapps.com">
                    apps@straitonapps.com
                  </a>
                  ;
                </li>
              </ul>
              If your purchase was made (free trial was received) via the App
              Store:
              <ul>
                <li>
                  please follow the App Store’s guidelines, e.g.{" "}
                  <a
                    rel="noreferrer"
                    href="https://support.apple.com/en-us/118428"
                    target="_blank"
                  >
                    If you want to cancel a subscription from Apple
                  </a>
                  ,{" "}
                  <a
                    rel="noreferrer"
                    href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
                    target="_blank"
                  >
                    If you want to cancel a subscription from Google.
                  </a>
                </li>
              </ul>
            </p>
            <p>
              After cancellation, you will still be able to use the Product for
              the term you have already paid for or until the end of the free
              trial. If you enjoyed your above right to terminate these Terms
              and unless these Terms were terminated by us earlier, these Terms
              and your rights to use the Product will terminate on: (a) the last
              day of your paid subscription period or (b) the last day of your
              free trial.{" "}
            </p>
            <p>
              DELETING THE APP DOES NOT CANCEL YOUR SUBSCRIPTION OR FREE TRIAL.
              TO AVOID BEING CHARGED FOR AUTO-RENEWAL, YOU MUST CANCEL YOUR
              SUBSCRIPTION OR FREE TRIAL AT LEAST 24 HOURS BEFORE THE END OF THE
              PAID SUBSCRIPTION PERIOD OR FREE TRIAL. Once your subscription or
              free trial access is canceled, you may also terminate your user
              account by using the appropriate deletion functions, if available
              through the Product.
            </p>
          </li>
          <li>
            <strong>
              10.3. OUR RIGHT TO SUSPEND YOUR ACCESS OR TERMINATE THESE TERMS{" "}
            </strong>
            <p>
              We have the right to terminate these Terms according to the
              procedure stipulated below.{" "}
            </p>
            <h4>i. Termination without prior notice</h4>
            <p>
              We may cancel your subscription or free trial access to the
              Product (as well as terminate your user account) under the
              following serious circumstances and without prior notice:
            </p>
            <ol className={classes.numberList}>
              <li>
                {" "}
                you violated these Terms and/or other policies that may apply to
                you;
              </li>
              <li> you violated applicable law;</li>
              <li>
                {" "}
                you provided us with any information that is untrue, inaccurate,
                or incomplete, or we have reasonable grounds to suspect that
                such is the case;
              </li>
              <li>
                {" "}
                you have not paid the fees or charges due to us in connection
                with the Product;
              </li>
              <li>
                {" "}
                you requested the deletion of your account / personal data
                (relevant for the Web Purchases only);
              </li>
              <li>
                {" "}
                we received a request from your bank, law enforcement, or other
                governmental agencies to terminate your user account;{" "}
              </li>
              and/or
              <li>
                {" "}
                we are required to terminate your user account in order to
                comply with applicable law.
              </li>
            </ol>
            <p>
              In the above-mentioned circumstances, these Terms and your rights
              to use the Product will terminate on: (a) the day of cancellation
              of your paid subscription or (b) the day of cancellation of your
              free trial.
            </p>
            <p>
              {" "}
              At the same time, we may allow you to use the Product until the
              end of your paid subscription period or until the end of your free
              trial. If this happens, these Terms and your rights to use the
              Product will terminate on: (a) the last day of your paid
              subscription period or (b) the last day of your free trial. Also,
              we reserve the right, at our absolute discretion, to terminate any
              free trial offer and your access to the Product during the free
              trial without notice and with no liability. If this happens, these
              Terms and your rights to use the Product will terminate on the day
              of cancellation of the free trial offer.
            </p>
            <p>
              {" "}
              In addition to our termination rights, we may instead choose to
              suspend your account, subscription, or access if any of the
              scenarios described above occur, or if we deem such suspension
              otherwise advisable to protect the Product, our systems or
              information, or those of you or any third party. In such an event,
              we will use commercially reasonable efforts to promptly notify you
              of the reason for such termination and to restore access once the
              underlying issue has been resolved to our satisfaction.{" "}
            </p>
            <h4>ii. Termination upon prior notice </h4>
            <p>
              We may decide to stop supporting or offering the Product, or to
              terminate or refuse to renew your subscription. We may do this at
              any time and at our own discretion, by notifying you accordingly
              (by email or through the Product). In such case, we will either
              allow you to continue accessing the Product until the end of your
              then-current paid subscription period or free trial (at which
              point these Terms will automatically terminate on such date), or
              will refund you the pro rata portion of the prepaid amounts
              corresponding to the remaining period of your then-current paid
              subscription (in which case these Terms will terminate upon the
              date of such refund). Once these Terms are terminated, we may
              terminate your user account as well.
            </p>
          </li>
          <li>
            <strong>10.4. PROVISIONS THAT SURVIVE TERMINATION</strong>

            <p>
              {" "}
              The following sections of these Terms shall survive termination of
              these Terms and shall remain in full force and effect: Our
              Product, Our Contract With You, Important Disclaimers, Fees and
              Payments, Unauthorized Use, Limitation of Liability, Indemnity,
              Applicable Law, Dispute Resolution, Miscellaneous Provisions,
              Contacts, as well as any other sections of these Terms that,
              either explicitly or by their nature, must remain in effect even
              after termination of these Terms.
            </p>
          </li>
        </ol>
        <li className={classes.title}>11. Applicable Law</li>
        <ol>
          <li>
            <strong>11.1. </strong>
            These Terms and any dispute or claim arising out of or in connection
            with them or their subject matter or formation shall be governed by
            and construed in accordance with the law of the Republic of Cyprus,
            without regard to Cyprus's choice or conflicts of law principles. In
            addition, if you reside in the United States or the European Union,
            or the United Kingdom, the law of your place of residence may also
            be applicable where such law contains mandatory consumer law
            provisions.
          </li>
        </ol>
        <li className={classes.title}>12. Dispute Resolution</li>
        <ol>
          <li>
            <strong>12.1. INFORMAL PROCESS FIRST</strong>{" "}
            <p>
              {" "}
              We are always interested in resolving disputes amicably and
              efficiently. If you have any dispute with the Company, you agree
              that before taking any formal action, you will contact us at{" "}
              <a href="mailto:apps@straitonapps.com">
                apps@straitonapps.com
              </a>{" "}
              and provide a brief, written description of the dispute. The
              parties agree to use their best efforts to settle any dispute,
              question, or disagreement directly through negotiations
              therebetween.
            </p>
          </li>
          <li>
            <strong>12.2. ARBITRATION AGREEMENT </strong>
            <p>
              PLEASE READ THIS SECTION (THE{" "}
              <strong>“ARBITRATION AGREEMENT”</strong>) CAREFULLY, SINCE IT
              AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT
              IN COURT.
            </p>{" "}
            <h4>a. Applicability of the Arbitration Agreement</h4> You and the
            Company agree that any dispute, claim, or controversy between you
            and the Company arising in connection with or relating in any way to
            these Terms or to your relationship with the Company as a user of
            the Product (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims
            arise during or after the termination of these Terms) will be
            determined by mandatory binding individual (not class) arbitration.
            YOU AND THE COMPANY THUS GIVE UP THE RIGHT TO GO TO COURT TO ASSERT
            OR DEFEND RIGHTS UNDER THESE TERMS (EXCEPT FOR MATTERS THAT MAY BE
            TAKEN TO SMALL CLAIMS COURT OR FOR PROTECTION OF THE COMPANY’S
            INTELLECTUAL PROPERTY RIGHTS OR WHEN USING YOUR 30-DAY RIGHT TO OPT
            OUT OF THE ARBITRATION AGREEMENT, AS SET FORTH BELOW). This
            Arbitration Agreement shall survive termination of these Terms.
            <h4>b. Exception - Litigation of Small Claims Court Claims</h4>{" "}
            <p>
              Notwithstanding the parties’ decision to resolve all disputes
              through arbitration, either party may also seek relief in a small
              claims court for disputes or claims within the scope of that
              court’s jurisdiction.{" "}
            </p>{" "}
            <h4>
              c. Exception - Protection of the Company’s Intellectual Property
              Rights
            </h4>{" "}
            <p>
              The Company retains the right to seek injunctive or other
              equitable relief from a court to prevent (or enjoin) the
              infringement or misappropriation of its intellectual property
              rights.{" "}
            </p>{" "}
            <h4>d. Exception - 30-Day Right to Opt Out Used</h4>{" "}
            <p>
              {" "}
              You have the right to opt out and not be bound by this Arbitration
              Agreement (including class action waiver provisions) by sending
              written notice of your decision to opt out to the appropriate
              contact means within 30 days of accepting these Terms; otherwise,
              you shall be bound to arbitrate disputes in accordance with these
              Terms.{" "}
            </p>{" "}
            <h4>e. Arbitration Procedure </h4>
            <p>
              <i>Overview.</i> Arbitration is more informal than a lawsuit in
              court. Arbitration uses a neutral arbitrator instead of a judge or
              jury, allows for more limited discovery than in court, and is
              subject to very limited review by courts. The arbitrator can award
              the same damages and relief that a court can award and nothing in
              this Arbitration Agreement shall be interpreted as limiting any
              non-waivable statutory rights. The arbitrator is bound by the
              terms of this Arbitration Agreement. All issues are for the
              arbitrator to decide, including issues relating to the scope and
              enforceability of this Arbitration Agreement. The arbitrator’s
              award shall be final and binding on all parties, except (1) for
              judicial review expressly permitted by law or (2) if the
              arbitrator’s award includes an award of injunctive relief against
              a party, in which case that party shall have the right to seek
              judicial review of the injunctive relief in a court of competent
              jurisdiction that shall not be bound by the arbitrator’s
              application or conclusions of law.
            </p>
            <p>
              <i>Rules.</i> Any arbitration between you and the Company will
              take place under the Consumer Arbitration Rules of the American
              Arbitration Association ("AAA") in force at the time of initiating
              the arbitration ("AAA Rules"), as modified by this Arbitration
              Agreement. The AAA Rules, as well as instructions on how to file
              an arbitration proceeding with the AAA, appear at{" "}
              <a rel="noreferrer" href="http://www.adr.org" target="_blank">
                www.adr.org
              </a>
              , or you may call the AAA at 1-800-778-7879.{" "}
            </p>{" "}
            <p>
              <i>Commencing an Arbitration.</i> Either you or we may start
              arbitration proceedings. If you elect to seek arbitration, you
              must first send the Company written Notice of your claim
              (“Notice”). The Notice to the Company should be sent by any of the
              following means: (i) by electronic or certified mail (Attention:
              Legal Counsel) using the appropriate contact means. If the Company
              initiates arbitration, it will send a written Notice to the email
              address used for your account or to your other available contact
              means. The Notice, whether sent by you or by the Company, must (a)
              describe the nature and basis of the claim or dispute; and (b) set
              forth the specific relief sought. If you and the Company do not
              reach an agreement to resolve the claim within 30 days after the
              Notice is received, you or the Company may commence an arbitration
              proceeding. Your claim may be filed either (a) by mail to the
              mailing address of the AAA’s Case Filing Services, which may be
              subject to change from time to time; or (b) online using the
              online form available at{" "}
              <a rel="noreferrer" href="http://www.adr.org" target="_blank">
                www.adr.org
              </a>
              ; or (c) at any of the AAA’s offices. Once your claim is filed,
              corresponding feedback and instructions from the AAA will follow
              in due course.{" "}
            </p>
            <p>
              <i>Fees.</i> If you choose to file an arbitration proceeding and
              you are required to pay a filing fee, the Company will reimburse
              you for that filing fee, unless your claim is for greater than the
              US $10,000 or the arbitrator determines your claim is filed for
              purposes of harassment or is patently frivolous, in which event
              you will be responsible for filing fees. Except as expressly set
              forth herein, the payment of all filing, administration, and
              arbitrator fees will be governed by the AAA Rules. Notwithstanding
              the above, the parties shall be responsible for paying their own
              attorneys’ fees unless the arbitration rules and/or applicable law
              provide otherwise. The arbitrator may make rulings and resolve
              disputes as to the payment and reimbursement of fees or expenses
              at any time during the proceeding and upon request from either
              party made within 14 days of the arbitrator's ruling on the
              merits.
            </p>
            <p>
              <i>Documents-only procedure, videoconference.</i> Where no
              disclosed claims or counterclaims exceed US $25,000, the dispute
              shall be resolved according to the procedures for the resolution
              of disputes through document submission (the parties submit their
              arguments and evidence to the arbitrator in writing; the
              arbitrator then makes an award based only on the documents; no
              in-person or telephone hearing is held), unless a party asks for a
              hearing or the arbitrator decides that a hearing is necessary.
              Other arbitration hearings will be conducted by videoconference to
              the extent possible, but if the arbitrator determines that a
              hearing should be conducted in person, the locale for such hearing
              shall be determined by the arbitrator in accordance with the AAA
              Rules.{" "}
            </p>
            <p>
              <i>Language.</i> The language of the arbitration shall be English.{" "}
            </p>
            <p>
              <i>Decision.</i> Regardless of the manner in which the arbitration
              is conducted, the arbitrator shall issue a reasoned written
              decision sufficient to explain the essential findings and
              conclusions on which the decision and award, if any, are based.
              During the arbitration, the amount of any settlement offer made by
              you or the Company shall not be disclosed to the arbitrator until
              after the arbitrator makes a final decision and award, if any.{" "}
            </p>
            <p>
              <i>Timeline of Arbitration.</i> The award shall be issued promptly
              by the arbitrator and, unless the parties agree differently or the
              law indicates a different time frame, no later than 30 calendar
              days from the date the hearing is closed, or, if the case is a
              documents-only procedure, 14 calendar days from the date the
              arbitrator set for his or her receipt of the final statements and
              proofs.
              <h4>
                f. No class or Representative Proceedings; Class action waiver
              </h4>
              <p>
                {" "}
                YOU AND THE COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING. Further, unless both you and the
                Company agree otherwise, the arbitrator may not consolidate more
                than one person’s claims with your claims, and may not otherwise
                preside over any form of a representative or class proceeding.
                If this specific provision is found to be unenforceable, then
                the entirety of this arbitration provision shall be null and
                void.
              </p>
              <h4> g. Confidentiality</h4>
              <p>
                {" "}
                All documents and information disclosed in the course of the
                arbitration shall be kept strictly confidential by the recipient
                and shall not be used by the recipient for any purpose other
                than for purposes of the arbitration or the enforcement of the
                arbitrator's decision and award and shall not be disclosed
                except in confidence to persons on a need to know basis only or
                as required by applicable law.
              </p>
              <h4>
                {" "}
                h. Interpretation and Enforcement of the Arbitration Agreement
              </h4>
              <p>
                {" "}
                You and the Company agree that the U.S. Federal Arbitration Act
                governs the interpretation and enforcement of this Arbitration
                Agreement. If this Arbitration Agreement is invalidated in whole
                or in part, the parties agree that any dispute, claim, or
                controversy that, as a result of such invalidation, is no longer
                governed by this Arbitration Agreement, will be resolved by a
                competent court, determined according to applicable law.
              </p>
            </p>
          </li>
        </ol>
        <li className={classes.title}>13. Miscellaneous Provisions</li>
        <ol>
          <li>
            <strong>13.1. </strong>
            If any provision (part of a provision) of these Terms is held by an
            arbitrator or a court of competent jurisdiction to be illegal,
            invalid, or unenforceable, then such provision (part of the
            provision) shall be enforced to the maximum extent permissible so as
            to affect the intent of the parties, and the remainder of these
            Terms shall continue in full force and effect.{" "}
          </li>
          <li>
            <strong>13.2. </strong>
            The Company may assign its rights and obligations under these Terms
            to any other person, by any way, including by novation, and by
            accepting these Terms, you give the Company consent to any such
            assignment. Updated Terms indicating the assignee will be published
            within the Product, and you hereby confirm such approach to be
            enough and valid notice of the assignment of the Company’s rights
            and obligations under these Terms. You may not assign, delegate, or
            otherwise transfer your account or your rights and obligations under
            these Terms to any other person without our prior written consent.{" "}
          </li>
        </ol>
        <li className={classes.title}>
          14. Changes to These Terms, Product Updates
        </li>
        <ol>
          <li>
            <strong>14.1. </strong>
            We may make changes to these Terms from time to time by notifying
            you in advance of such changes by any reasonable means, including by
            posting the updated Terms within the Product (provided that, for
            material changes, we will seek to supplement such notice by an
            in-app pop-up message and/or an email). Any such changes will not
            apply to any dispute between you and us arising prior to the date on
            which the Terms were changed. Your use of the Product following any
            changes to these Terms will constitute your acceptance of such
            changes. If you do not wish to continue using the Product under the
            updated Terms, you may terminate them in accordance with the section
            "Termination of These Terms". The effective date set forth at the
            top of this document indicates when these Terms were last changed.
          </li>
          <li>
            <strong>14.2. </strong>
            From time to time we may automatically update the Product to improve
            performance, enhance functionality, reflect changes to the operating
            system, or address security issues. Alternatively, we may ask you to
            update the Product for these reasons. If you choose not to install
            such updates or if you opt out of automatic updates, you may not be
            able to continue using the Product.{" "}
          </li>
        </ol>
        <li className={classes.title}>15. Contacts</li>
        <ol>
          <li>
            <strong>15.1. COMMUNICATION PREFERENCES</strong>
            <p>
              {" "}
              We will send you information relating to your account and use of
              the Product (e.g. invoices, messages, notices) in electronic form
              only, for example via emails to the email address used for your
              account or push notifications. You agree that any notices,
              agreements, disclosures, or other communications we send to you
              electronically will satisfy any legal communication requirements,
              including that such communications be in writing.
            </p>
          </li>
          <li>
            <strong>15.2. CONTACTING US</strong>{" "}
            <p>
              {" "}
              To communicate any questions or complaints regarding the Product,
              submit any notice under these Terms, or request a copy of these
              Terms, please contact us:{" "}
            </p>
            <p className={classes.marginLeft}>
              If your purchase was made (free trial was received) directly on
              the Website or via the App Store at{" "}
              <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>.
            </p>
            <p>
              <strong>
                We encourage you to communicate with us via email, in which case
                we can effectively process your request and quickly resolve the
                issue or answer your questions. If you communicate your request
                on paper to our registered address and do not include enough
                identifiable information therein, we will not be able to find
                you in our user system and thus, will not be able to help
              </strong>
            </p>
          </li>
        </ol>
      </ol>
    </TermsWrapper>
  );
};
