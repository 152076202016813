import { useState } from "react";
import { DATA } from "./data";
import styles from "./styles.module.scss";
import { Button } from "../../../../../common";
import { usePreloadImages } from "../../../../../hooks/usePreloadImages";
import burnFat from "../burnFat/img/burnfat.jpg";
import flexible from "../flexible/img/bg.jpg";
import breath from "../breathing/img/bg.jpg";
import { DATA as struggleData } from "../issuesStrugling/data";
import body from "./img/body.png";

export const FocusAreas = ({ onNextStep }) => {
  usePreloadImages([...Object.values(struggleData), burnFat, flexible, breath]);

  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    const data =
      checked.length === Object.keys(DATA).length ? "Full body" : checked;
    console.log(data);
    onNextStep(data);
  };

  const handleCheckAll = () => {
    const focusAreas = Object.keys(DATA);
    if (checked.length === focusAreas.length) {
      setChecked([]);
      return;
    }
    setChecked(focusAreas);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What is your focus areas?</h2>
        <p className={styles.hint}>Choose all that apply</p>
        <div className={styles.cards}>
          {Object.entries(DATA).map(([title, img]) => (
            <div
              onClick={() => handleCheck(title)}
              className={
                checked.includes(title)
                  ? `${styles.card} ${styles.checked}`
                  : `${styles.card}`
              }
              key={title}
            >
              <p>{title}</p>
              <img src={img} alt={title} />
            </div>
          ))}
          <div
            onClick={handleCheckAll}
            className={
              checked.length === Object.values(DATA).length
                ? `${styles.card} ${styles.checked}`
                : `${styles.card}`
            }
            key={"Full body"}
          >
            <p>Full body</p>
            <img src={body} alt="Full body" />
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
