import { useState } from "react";
import styles from "./styles.module.scss";
import { Lock } from "../../../../assets/Icons";
import { Button } from "../../../../common";
import authService from "../../../../api/services/authService";
import { RegistrationLayout } from "../layout";
import { useNavigate } from "react-router";

export const Email = () => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    setEmail(value);
    setIsValid(validateEmail(value));
    setErrorMessage("");
  };

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    try {
      await authService.emailOnlySignUp(email);

      navigate("/custom-plan");
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong. Please try again."
        );
      } else {
        setErrorMessage("Network error. Please try again.");
      }
    }
    setIsLoading(false);
  };

  return (
    <RegistrationLayout hideBack>
      <div className={styles.container} id="breakdown">
        <div className={styles.content}>
          <h2>
            Enter your email to receive
            <br />
            <span>Your Custom Program</span>
          </h2>
          <div className={styles.input}>
            <input
              placeholder="Your email"
              type="email"
              value={email}
              onChange={handleChange}
              className={isValid}
            />
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}{" "}
            <div className={styles.hint}>
              <div>
                <Lock />
              </div>
              <p>
                We care about your privacy and are dedicated to protecting your
                personal information. We'll send you a copy of your results for
                your convenience.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <Button onClick={onSubmit} disabled={!isValid || isLoading}>
            Continue
          </Button>
        </div>
      </div>
    </RegistrationLayout>
  );
};
