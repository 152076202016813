import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserService } from "../../api/services/userService";

const initialState = {
  userInfo: null,
  isLoading: false,
};

export const getUserInfoThunk = createAsyncThunk("user/get", async () => {
  try {
    const res = await UserService.userInfo();
    return res.data;
  } catch (e) {
    console.log(e);
  }
});

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.userInfo = payload;
        }
        state.isLoading = false;
      })
      .addCase(getUserInfoThunk.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export const reducer = userSlice.reducer;
