import { Button } from "../../../common";
import styles from "./styles.module.scss";
import { DATA } from "../stepper/questions/futureEvent";
import { ChartLoseWeight } from "./ChartLoseWeight";
import { ChartGainWeight } from "./ChartGainWeight";
import { useGetUserInfo } from "../../../hooks/useGetUserProfile";
import { Loader } from "../../../common/loader";

export const Prediction = ({ onNextStep }) => {
  const { user, loaded } = useGetUserInfo();
  const ms = localStorage.getItem("ms");

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;
  const predictedWeight =
    ms === "us"
      ? user?.fitnessProfile?.weightLbsEstimateByOccasionDate
      : user?.fitnessProfile?.weightKgEstimateByOccasionDate;

  const predictedDate = user?.fitnessProfile?.weightGoalCompletionEstimatedDate;
  const eventResponse = user?.questions.qqOccasion;
  const event = DATA.find((item) => item.value === eventResponse);
  const eventDate = user?.questions?.qqOccasionDate;

  const isLoseWeight = weight > goal && weight !== goal;

  const averageWeight = Math.round((weight + goal) / 2);
  const gainWeightMiddle = Math.round(weight + (averageWeight - weight) * 1.5);
  const gainWeightEnd = weight + (averageWeight - weight) * 3;

  const weightChange =
    ms === "us"
      ? user?.fitnessProfile?.monthlyEstimatedWeightChangeLbs
      : user?.fitnessProfile?.monthlyEstimatedWeightChangeKg;

  const axisY = isLoseWeight
    ? [weight, averageWeight, goal]
    : [gainWeightEnd, gainWeightMiddle, weight];

  return (
    <>
      {loaded ? (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.text}>
              <h2>The only program you'll ever need to get your dream body</h2>
              <p className={styles.subtitle}>We predict you'll be</p>
              <p className={styles.predictedData}>
                {goal} {ms === "us" ? "lbs" : "kg"} <span>by</span>{" "}
                {new Date(predictedDate).toLocaleString("en-US", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </p>
              {eventResponse && eventDate && (
                <div className={styles.event}>
                  {`~${Math.round(predictedWeight - weight) || weightChange} ${
                    ms === "us" ? "lbs" : "kg"
                  } by the `}
                  {event?.displayValue}{" "}
                  <div className={styles.icon}>{event?.icon}</div>
                </div>
              )}
            </div>

            <div
              className={
                loaded ? styles.chart : `${styles.chart} ${styles.hidden}`
              }
            >
              {eventResponse && eventDate && (
                <div className={styles.eventLabel}>
                  {event?.displayValue} -{" "}
                  {new Date(eventDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </div>
              )}
              <div className={styles.axisx}>
                <span>
                  {" "}
                  {new Date().toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })}
                </span>
                <span>
                  {" "}
                  {new Date(predictedDate).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })}
                </span>
              </div>
              <div className={styles.axisy}>
                {axisY.map((value, index) => {
                  return <span key={index}>{value}</span>;
                })}
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.label}`
                    : `${styles.label} ${styles.gain}`
                }
              >
                Goal <br />
                {goal} {ms === "us" ? "lbs" : "kg"}
              </div>

              {isLoseWeight ? <ChartLoseWeight /> : <ChartGainWeight />}
            </div>
            <div className={styles.hint}>
              *Established on the data provided by the users to the app. Check
              with your doctor first. This chart is just a visual representation
              of a possible fitness journey.
            </div>
          </div>

          <div className={styles.action}>
            <Button onClick={() => onNextStep()}>Continue</Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
