export const PlanCheckbox = ({ checked }) => {
  return checked ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
        fill="#7C8AF7"
      />
      <path
        d="M14.9499 6.78349C14.7382 6.59948 14.4174 6.62193 14.2334 6.83363C14.2295 6.83806 14.2257 6.84257 14.222 6.84717L8.75049 12.8496L5.83826 10.4059C5.62029 10.2294 5.30049 10.263 5.12396 10.481C4.95175 10.6936 4.97894 11.0044 5.18546 11.1839L8.48668 13.954C8.70154 14.1342 9.02179 14.1062 9.20207 13.8914L15 7.5C15.184 7.28827 15.1616 6.9675 14.9499 6.78349Z"
        stroke="white"
        strokeWidth="1.1"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
    </svg>
  );
};
