import React, { useState } from "react";
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useFetchPlans } from "../hooks/useFetchPlans";
import { useNavigate } from "react-router";
import { successToast } from "../../../../../../utils/toaster";
import styles from './styles.module.scss';

export const ApplePayButton = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();
  const { initiatePayment } = useFetchPlans();

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const { stripeClientSecret } = await initiatePayment({
      paymentMethod: "APPLE_PAY",
      paymentProvider: "STRIPE",
    });

    const payload = await stripe.confirmPayment({
      elements,
      clientSecret: stripeClientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://easyfit.me",
      },
    });
    console.log(payload);

    if (payload.error) {
      setErrorMessage(payload.error.message);
    } else {
      navigate("/activate");
      successToast({ message: "Payment successful! Thank you!" });
    }
  };

  return (
    <>
      <ExpressCheckoutElement className={styles.applepay} onConfirm={onConfirm} />
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};
