import { UserService } from "../api/services/userService";
import { useEffect, useState } from "react";
import { formatFitnessProfile } from "../utils/formatProfile";

export const useGetFitnessProfile = () => {
  const [profile, setProfile] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoaded(false);
        const res = await UserService.userInfo();
        setProfile(formatFitnessProfile(res.data.fitnessProfile));
      } catch (e) {
        console.log(e);
      } finally {
        setLoaded(true);
      }
    };

    getUserInfo();
  }, []);

  return { profile, loaded };
};
