import React, { useState } from "react";
import styles from "./styles.module.scss";

export const FAQItem = ({ question, answer, defaultExpanded }) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.faqItem}>
      <div className={styles.question} onClick={toggleOpen}>
        <p>{question}</p>
        <div className={`${styles.arrow} ${isOpen ? styles.open : ""}`}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 10L12 15L17 10"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {isOpen && <p className={styles.answer}>{answer}</p>}
    </div>
  );
};
