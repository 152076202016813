import { useState } from "react";
import { Button, ImageCard } from "../../../../../common";
import { DATA } from "./data";
import styles from "./styles.module.scss";

export const IssuesStruggling = ({ onNextStep }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }

      if (value === "None of the above") {
        return ["None of the above"];
      }

      return [
        ...prevState.filter((item) => item !== "None of the above"),
        value,
      ];
    });
  };

  const handleNextStep = () => {
    console.log(checked);
    onNextStep(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Do you struggle with any of these issues?</h2>
        <p className={styles.hint}>Choose all that apply</p>
        <div className={styles.cards}>
          {Object.entries(DATA).map(([value, img]) => (
            <ImageCard
              key={value}
              onClick={() => handleCheck(value)}
              checked={checked.includes(value)}
              img={img}
              text={value}
            />
          ))}
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={checked.length === 0} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
