import {} from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";
import { useStripeElements } from "../../stripeElementsContext";

const StripeCardElements = ({ onChange }) => {
  const { CardNumberElement, CardExpiryElement, CardCvcElement } =
    useStripeElements();

  return (
    <div className={styles.inputs}>
      <div className={styles.cardNumberElement}>
        <CardNumberElement
          className={styles.cardInput}
          onChange={onChange}
          options={{
            placeholder: "XXXX XXXX XXXX XXXX",
          }}
        />
      </div>

      <div className={styles.splitFields}>
        <div className={styles.expiryDate}>
          <CardExpiryElement
            className={styles.inputField}
            options={{
              placeholder: "MM/YY",
            }}
          />
        </div>

        <div className={styles.cvv}>
          <CardCvcElement
            className={styles.inputField}
            options={{
              placeholder: "CVV",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default StripeCardElements;
