import { DATA } from "./data";
import styles from "./styles.module.scss";

export const Flexible = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
  };
  return (
    <div className={styles.container}>
      <h2>How flexible are you?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key)}
            key={key}
            className={styles.card}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
