import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  // paymentPageUri: '',
  plans: [],
};

export const getSubscriptionPlans = createAsyncThunk(
  "getInitialUserData",
  async (_, { dispatch }) => {
    try {
      const result = await axiosInstance.get("subscription-plans");
      dispatch(
        setPlans(
          result.data.items.reverse().map((item, index) => {
            if (index === 0) {
              return { ...item, ...{ active: true } };
            }

            return { ...item, ...{ active: false } };
          })
        )
      );
    } catch (error) {}
  }
);

export const openPayPal = createAsyncThunk("openPayPal", async (id) => {
  try {
    const response = await axiosInstance.post(
      "me/initial-payment-requests",
      {
        subscriptionPlanId: id,
      },
      {
        headers: {
          Authorization: localStorage.getItem("FY-AuthToken"),
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
});

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setPlans(state, { payload }) {
      state.plans = payload;
    },
    setActivePlan(state, { payload }) {
      state.plans = state.plans.map((item) => {
        item.active = false;

        if (item.id === payload) {
          item.active = true;
        }
        return item;
      });
    },
    // resetPaymentPageUri(state) {
    //   state.paymentPageUri = '';
    // },
    reset() {
      return initialState;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(openPayPal.pending, (state, { payload }) => {
  //       state.isLoading = true;
  //     })
  //     .addCase(openPayPal.fulfilled, (state, { payload }) => {
  //       state.paymentPageUri = payload;
  //       state.isLoading = false;
  //     })
  // }
});

export const reducer = subscriptionSlice.reducer;

export const { reset, setPlans, setActivePlan } = subscriptionSlice.actions;
