import { UserService } from "../../../../../api/services/userService";
import { Button, Input } from "../../../../../common";
import { ftInToFt, kgToLbs, lbsToKg } from "../../../../../utils/measurements";
import { GoalHint } from "./goalHint";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";

const Measures = {
  us: "lbs",
  eu: "kg",
};

export const WeightGoal = ({ onNextStep }) => {
  const ms = localStorage.getItem("ms");
  const [weight, setWeight] = useState();
  const [isValid, setIsValid] = useState(false);
  const [measurements, setMeasurments] = useState();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await UserService.userInfo();
        const dataToState =
          ms === "us"
            ? {
                height: ftInToFt(
                  res.data?.questions?.qqHeightFt,
                  res.data?.questions?.qqHeightIn
                ),
                weight: res.data.questions.qqWeightLbs,
              }
            : {
                height: res.data.questions.qqHeightCm,
                weight: res.data.questions.qqWeightKg,
              };
        setMeasurments(dataToState);
      } catch (e) {
        console.log(e);
      }
    };

    getUserInfo();
  }, []);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setWeight("");
      setIsValid(false);
      return;
    }

    if (value.length <= 3 && !isNaN(value)) {
      const numericValue = Number(value);
      setWeight(numericValue);

      if (ms === "us") {
        setIsValid(numericValue <= 662 && numericValue >= 55);
      } else {
        setIsValid(numericValue <= 300 && numericValue >= 25);
      }
    }
  };
  const handleNextStep = () => {
    const key = ms === "us" ? "qqGoalWeightLbs" : "qqGoalWeightKg";
    try {
      UserService.updateQuestions({ [key]: weight });
    } catch (e) {
      console.log(e);
    }
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Great! And what's your weight goal?</h2>
        <div className={styles.input}>
          <Input
            inputmode="numeric"
            type="number"
            step="1"
            placeholder={"Weight goal"}
            value={weight}
            onChange={handleChange}
            endAdornment={<h5>{Measures[ms]}</h5>}
          />

          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>
              {ms === "eu"
                ? `${25} kg to ${300} kg`
                : `${kgToLbs(25).toFixed(0)} lbs to ${kgToLbs(300).toFixed(
                    0
                  )} lbs`}
            </span>
          </p>
        </div>
        {isValid && !(weight === measurements.weight) && (
          <GoalHint
            height={measurements?.height}
            weight={measurements?.weight}
            weightGoal={weight}
          />
        )}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
