import {
  Fire,
  FitnessSlimWaist,
  FitnessWeights,
  Running,
} from "../../../../../assets/Icons";
import { Button } from "../../../../../common";
import { BMIHint } from "./BMIHint";
import BMIScale from "./BMIScale";
import styles from "./styles.module.scss";
import Normal from "./img/normal.jpg";
import Overweight from "./img/overweight.jpg";
import Underweight from "./img/underweight.jpg";
import Obese from "./img/obese.jpg";
import { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./fade.css";

import { useGetFitnessProfile } from "../../../../../hooks/useGetFitnessProfile";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
};

export const Report = ({ onNextStep }) => {
  const { profile } = useGetFitnessProfile();
  const nodeRef = useRef();

  const bmiType = profile?.bodyMassType;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h2>Here's your fitness report</h2>
        <div className={styles.content}>
          <h3>Body Mass Index (BMI)</h3>
          <BMIScale bmi={profile?.bodyMassIndex} />
          <div
            className={
              !!profile ? `${styles.hint}` : `${styles.hidden} ${styles.hint}`
            }
          >
            <BMIHint bmiType={bmiType} />
          </div>
          <SwitchTransition mode="out-in">
            <CSSTransition
              in={!!profile}
              nodeRef={nodeRef}
              timeout={700}
              classNames="fade-in"
              key={profile}
            >
              <div
                ref={nodeRef}
                className={
                  !!profile
                    ? `${styles.summary}`
                    : `${styles.summary} ${styles.hidden}`
                }
              >
                <div className={styles.summaryItems}>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <FitnessSlimWaist />
                    </div>
                    <div className={styles.text}>
                      <p>Body Type</p>
                      <h5>{profile?.bodyType}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <Running />
                    </div>
                    <div className={styles.text}>
                      <p>Lifestyle</p>
                      <h5>{profile?.lifestyleType || "n/a"}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <FitnessWeights />
                    </div>
                    <div className={styles.text}>
                      <p>Fitness level</p>
                      <h5>{profile?.fitnessLevel}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <Fire />
                    </div>
                    <div className={styles.text}>
                      <p>Metabolism</p>
                      <h5>{profile?.metabolismType}</h5>
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img
                    src={
                      BMI_TYPE_IMAGES[bmiType] || BMI_TYPE_IMAGES.Underweight
                    }
                    alt={bmiType}
                  />
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={() => onNextStep()}>Continue</Button>
      </div>
    </div>
  );
};
