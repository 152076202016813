import { useEffect, useRef, useState } from "react";
import { Button, Input, OnboardingLayout } from "../../common";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoThunk } from "../../redux/slices/userSlice";
import { Loader } from "../../common/loader";
import { createRef } from "react";
import { axiosInstance } from "../../api";
import { errorToast } from "../../utils/toaster";
import { useNavigate } from "react-router";
import {
  Check,
  CheckOutlined,
  ErrorOutlined,
  Warning,
} from "../../assets/Icons";

const CODE_SIZE = 4;

export const UserActivation = () => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailShow, setIsEmailShow] = useState(false);
  const { userInfo, isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [code, setCode] = useState(["", "", "", ""]);
  const inputsRefs = useRef(code.map(() => createRef()));
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    dispatch(getUserInfoThunk());
  }, []);

  const changeInputHandler = (index) => (e) => {
    const value = e.currentTarget.value;

    setCode((prevState) => {
      const arr = [...prevState];
      arr[index] = value;

      return arr;
    });
    if (!value && index > 0) {
      inputsRefs.current[index - 1].current.focus();
      return;
    }
    if (value && index < CODE_SIZE - 1) {
      inputsRefs.current[index + 1].current.focus();
    }
  };

  const handleSubmitCode = async () => {
    const pin = code.join("");

    try {
      setIsSubmitting(true);
      await axiosInstance.post("auth/email-and-pin-sign-in", {
        email: userInfo?.email,
        pin,
      });
      navigate("/thankyou");
    } catch (e) {
      errorToast({ message: e.response.data.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (code.join("").length === CODE_SIZE) {
      setIsValid(true);
    }
  }, [code]);

  useEffect(() => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const handleSubmitEmail = async () => {
    try {
      const res = await axiosInstance.post("me/change-email", {
        newEmail: email,
      });
      await dispatch(getUserInfoThunk());
      setIsEmailShow(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <OnboardingLayout
      hideNavigateBack={!isEmailShow}
      onNavigateBack={() => setIsEmailShow(false)}
    >
      {!isLoading ? (
        <div className={styles.activate}>
          {!isEmailShow ? (
            <div className={styles.code}>
              <h5 className={styles.title}>
                We’ve sent a one-time password to{" "}
                <strong>{userInfo?.email}</strong>
              </h5>
              <button
                className={styles.emailButton}
                onClick={() => setIsEmailShow(true)}
              >
                Use a different email
              </button>
              <div className={styles.codeInput}>
                {Array.from(Array(CODE_SIZE).keys()).map((value) => (
                  <input
                    ref={inputsRefs.current[value]}
                    maxLength={1}
                    onChange={changeInputHandler(value)}
                    className={styles.codeInputItem}
                    key={value}
                  />
                ))}
              </div>
              <div className={styles.actions}>
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmitCode}
                >
                  LOG IN
                </Button>
                <button className={styles.resend}>RESEND CODE</button>
              </div>
            </div>
          ) : (
            <div className={styles.email}>
              <h5 className={styles.emailTitle}>Enter a new email</h5>
              <div className={styles.input}>
                <label htmlFor="email">Email</label>
                <Input
                  endAdornment={
                    email &&
                    (isEmailValid ? (
                      <CheckOutlined />
                    ) : (
                      <ErrorOutlined color="red" />
                    ))
                  }
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  id="email"
                />
              </div>
              <div className={styles.actions}>
                <Button onClick={handleSubmitEmail}>SEND CODE</Button>
                <a href="mailto:support@easyfit.io">Contact us</a>
              </div>
            </div>
          )}
          {!isEmailShow && (
            <div className={styles.footer}>
              For any other questions please contact{" "}
              <a href="mailto:support@easyfit.io">support@easyfit.io</a>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </OnboardingLayout>
  );
};
