import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { initializePaddle } from "@paddle/paddle-js";
import { useFetchPlans } from "../hooks/useFetchPlans";
import styles from "./styles.module.scss";
import { successToast } from "../../../../../../utils/toaster";
import paypal from "./PayPal.png";

const PaddleCheckout = () => {
  const [paddle, setPaddle] = useState();
  const { handleSubmitPlanPaddle } = useFetchPlans();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(process.env);
    initializePaddle({
      checkout: {
        settings: {
          allowedPaymentMethods: ["paypal"],
          displayMode: "inline",
          variant: "one-page",
          theme: "light",
          locale: "en",
          frameTarget: "checkout-container",
          frameInitialHeight: "450",
          frameStyle:
            "width: 100%; min-width: 312px; background-color: transparent; border: none;",
        },
      },
      environment: process.env.REACT_APP_PADDLE_ENV,
      token: process.env.REACT_APP_PADDLE_TOKEN,
      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          setTimeout(() => {
            paddle?.Checkout.close();
            successToast({ message: "Payment successful! Thank you!" });
            navigate("/activate");
          }, 5000);
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [paddle]);

  const handleOpenPaddleCheckout = async () => {
    const transactionId = await handleSubmitPlanPaddle();
    paddle?.Checkout.open({
      transactionId,
    });
  };

  return (
    <>
      <button className={styles.paypal} onClick={handleOpenPaddleCheckout}>
        <img src={paypal} alt="pp" />
      </button>
      <div id="checkout-container" className="checkout-container"></div>
    </>
  );
};

export default PaddleCheckout;
