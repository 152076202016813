import { UserService } from "../api/services/userService";
import { useEffect, useState } from "react";

export const useGetUserInfo = () => {
  const [user, setUser] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoaded(false);
        const res = await UserService.userInfo();
        setUser(res.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoaded(true);
      }
    };

    getUserInfo();
  }, []);

  return { user, loaded };
};
