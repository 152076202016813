import { PersonCircle } from "../../../../../assets/Icons";
import { Button, Input } from "../../../../../common";
import styles from "./styles.module.scss";
import { useState } from "react";

export const Age = ({ onNextStep }) => {
  const [age, setAge] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setAge("");
      setIsValid(false);
      return;
    }

    if (value.length <= 2 && !isNaN(value)) {
      const numericValue = Number(value);
      setAge(numericValue);

      setIsValid(numericValue <= 80 && numericValue >= 18);
    }
  };

  const handleNextStep = () => {
    console.log(age);
    onNextStep(age);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What is your age?</h2>
        <div className={styles.input}>
          <Input
            inputmode="numeric"
            type="number"
            step="1"
            placeholder={"Age"}
            value={age}
            onChange={handleChange}
            endAdornment={<h5>years</h5>}
          />

          <p className={styles.hint}>
            Please, enter a value from <span>18 to 80</span>
          </p>
        </div>
        {!!age && (
          <div className={styles.card}>
            <PersonCircle />
            <h6>We ask about your age to create a custom program.</h6>
            <p>
              We will adept a personalized exercise program according to your
              age and needs.
            </p>
          </div>
        )}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
